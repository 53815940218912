/*

FORMEDIT

COMPONENTE QUE MONTA LOS FORMULARIOS PARA CREAR, EDITAR, DUPLICAR O ELIMINAR REGISTROS A CUALQUIER TABLA

ULTIMAS MODFIFICACIONES:

  - 2021-06-16 > AÑADIDO controlledList PARA LOS SUBLISTADOS QUE DEBEN ACTUALIZARSE AL CAMBIAR ALGO DEL ESTADO
  - 2021-06-16 > AÑADIDO autoSave PARA GUARDAR LOS DATOS A CADA CAMBIO, SIN DARLE AL BOTON DE GUARDAR
  - 2021-06-16 > PASAMOS idValue AL STATE PARA PODER ACTUALIZARLO CON EL autoSave EN LA CREACION DE UN NUEVO REGISTRO
  - 2021-06-17 > HACEMOS EL componentDidMount INDEPENDIENTE DE fetchApi PARA AÑADIRLE QUE CARGUE LOS VALORES INICIALES DE LOS SELECTS QUE SON GENERICOS
  - 2021-06-17 > PASAMOS EL RESTO DE CAMPOS DE PROPS AL STATE
  - 2021-06-19 > AÑADIDO TIPO CAMPO controlledEmail
  - 2021-06-19 > ADAPTADA FUNCION deleteRegister A $action
  - 2021-06-21 > CREADAS PROP dataExcludeComponent Y VARIABLE dataExcludeComponent CON LOS NOMBRES DE LAS VIARIABLES A NO ENVIAR A LA API PARA ACOTAR INFO ENVIADA
  - 2021-06-22 > AÑADIDA PROPIEDAD updateListForm QUE SI ES TRUE EJECUTA updateForm AL ELIMINARSE LINEAS DEL SUBCOMPONENTES controlledList
  - 2021-06-29 > ADAPTADO A sentData QUE CONTIENE $state Y ARCHIVOS
  - 2021-07-01 > AÑADIDO TIPO DE CAMPO imageFile
  - 2021-07-01 > AÑADIDO routeHidden PARA LOS CAMPO DE TIPO imageFile
  - 2021-07-06 > AÑADIDAS LABELS DE ERRORES DEBAJO DE CADA CAMPO PARA COMPROBACION DE OBLIGATORIOS
  - 2021-07-07 > ACABADA VALIDACION CAMPOS VACIOS 
  - 2021-07-07 > AÑADIDA PROPIEDAD fetchApi A LOS CAMPOS PARA LLAMAR A API AL CAMBIAR SI NO HAY autoSave 
  - 2021-07-16 > AÑADIDA VALIDACION DE CAMPO EMAIL AL ENVIAR
  - 2021-07-21 > AÑADIDO TOKEN EN LLAMADAS A API
  - 2021-07-25 > AÑADIDA FUNCION handleErrors
  - 2021-07-27 > SACAMOS FUNCIONES A Functions.js
  - 2021-07-31 > AÑADIDO DUPLICAR
  - 2021-11-11 > CONFIGURADO COMPONENTE TinyEditor EN LOCAL PARA CAMPOS TIPO editor
  - 2021-11-12 > AÑADIDO PARAMETRO pagination AL COMPONENTE Listado
  - 2021-11-20 > AÑADIDO TIPO CAMPO controlledNumber
  - 2021-12-14 > CAMBIADO ORDEN EN componentDidMount PARA QUE CARGUE PRIMERO LOS SELECTS DINAMICOS ANTES DEL FETCH A LA API
  - 2021-12-15 > AÑADIDA LLAMADA A loadingCompleted EN componentDidMount
  - 2022-01-11 > AÑADIDA PROPIEDAD initialFetch
  - 2022-01-11 > AÑADIDO TIPO CAMPO controlledCheckbox
  - 2022-02-03 > VINCULADOS READONLY DE controlledNumber y controlledDynamicSelect AL STATE
  - 2022-02-15 > EMPEZADO NUEVO TIPO wizard PARA GUIA DE PASOS EN PROCESO
  - 2022-02-15 > AÑADIDA PROPIEDAD submitButtonText ACTUALIZABLE DESDE LA API
  - 2022-02-18 > AÑADIDO bt_deleteReadOnly ACTUALIZABLE DESDE LA API
  - 2022-03-16 > AÑADIDA PROPIEDAD componentPadre PARA RECIBIR LOS PERMISOS DE COMPONENTES DESDE LA API
  - 2022-03-27 > AÑADIDAS PROPIEDAD Y VARIABLE ESTADO dataIncludeParentComponent, dataIncludeComponent PARA INCLUIR EN FETCH VARIBLES TIPO HIDDEN Y READONLY
  - 2022-03-28 > AÑADIDO TIPO CAMPO controlledParent
  - 2022-06-22 > AÑADIDA PROPIEDAD DEL MODAL modalType
  - 2022-06-22 > VUELTO A CAMBIAR ORDEN EN componentDidMount PARA QUE CARGUE LOS SELECTS DINAMICOS DESPUES DEL FETCH A LA API
  - 2022-07-13 > AÑADIDA LA FUNCION debouncedFetchApi
  - 2022-10-17 > PARAMETERIZED idField IN componentDidMount TO ASSIGN THE action="create" OR action="update"
  - 2022-10-17 > ADDED FIELD TYPE controlledTextarea

PROPIEDADES:

  - fields > EL ARRAY CON LOS CAMPOS DEL FORMULARIO (VER DEFINICIÓN MÁS ABAJO)
  - routeList > LA RUTA AL LISTADO DEL QUE SE HA INVOCADO EL FORMULARIO 
  - tablename > LA TABLA A LA QUE AFECTA
  - idField > EN CAMPO ID DE LA TABLA
  - deleteField > EL CAMPO DE BORRADO DE LA TABLA (SE PONE A 0 CUANDO SE ELIMINA UN REGISTRO)
  - idValue > EL VALOR DEL CAMPO ID DEL REGISTRO A TRATAR
  - idParent > EL VALOR DEL CAMPO ID DEL REGISTRO PADRE CON EL QUE ESTA RELACIONADO
  - routeApiControl > LA LLAMADA A LA API QUE DEVUELVE LOS DATOS DEL REGISTRO
  - routeApiDeleteFile > LA LLAMADA A LA API PARA ELIMINAR UN ARCHIVO ASOCIADO AL REGISTRO
  - routeApiDeleteImage > LA LLAMADA A LA API PARA ELIMINAR UNA IMAGEN ASOCIADA AL REGISTRO
  - routeApiDeleteImageFile > LA LLAMADA A LA API PARA ELIMINAR UNA IMAGEN TIPO ARCHIVO ASOCIADA AL REGISTRO
  - routeApiActions > LA LLAMADA A LA API PARA EJECUTAR LAS ACCIONES DE CREAR, GUARDAR, ELIMINAR O DUPLICAR
  - numberFiles > EL NÚMERO DE ARCHIVOS ASOCIADOS A CADA REGISTRO
  - numberImages > EL NÚMERO DE IMAGENES ASOCIADAS A CADA REGISTRO
  - numberImageFiles > EL NÚMERO DE IMAGENES TIPO ARCHIVO ASOCIADAS A CADA REGISTRO
  - readOnly > SI ES TRUE NO APARECERÁN LOS BOTONES DE ELIMINAR, NI GUARDAR, NI DUPLICAR
  - options > LOS BOTONES BAJO EL FORMULARIO. PUEDEN SER: bt_save bt_duplicate bt_back bt_delete
  - informativeHidden > SI ES TRUE NO SE MOSTRARAN LOS CAMPOS DE TIPO controlledInformative
  - autoSave > SI ES TRUE SE GUARDARAN LOS DATOS EN EL BACKEND A CADA CAMBIO EN EL FORMULARIO
  - dataExcludeParentComponent > ARRAY CON LOS CAMPOS A EXCLUIR EN LOS FETCH A API
  - updateListForm > SI ES TRUE EJECUTA updateForm AL ELIMINARSE LINEAS DEL SUBLISTADO
  - initialFetch > SI ES TRUE HACE EL FETCH A LA API EN EL componentDidMount
  - skipRedirectSubmit > SI ES TRUE NO REDIRECCIONA TRAS HACER EL SUBMIT EN handleValidSubmit
  - submitButtonText > SI ESTA DEFINIDO O SE ENVIA DESDE LA API MODIFICA EL TEXTO DE BOTON DE GUARDAR

LOS CAMPOS DEFINIDOS EN EL ARRAY fields PUEDEN SER DE TIPO:

  - parent > MONTA UN CAMPO <Input type="text" CON EL VALOR DE LA PROPIEDAD idParent DEL COMPONENTE
  - controlledParent > MONTA UN CAMPO <Input type="text" CON EL VALOR DE LA PROPIEDAD idParent DEL COMPONENTE
  - text > MONTA UN CAMPO <Input type="text" CON EL VALOR QUE TENGA LA VARIABLE DE ESTADO QUE SE LLAME COMO EL name DEL CAMPO
  - controlledText > COMO EL text PERO CON SU VALOR ATADO AL STATE
  - controlledInformative > COMO EL controlledText PERO CON EL HIDDEN ATADO A UNA VAR DEL STATE LLAMADA informativosHiddem
  - select > CAMPO <Select CON options Y SELECCIONADO POR DEFECTO EL VALOR DE LA VARIABLE DE ESTADO field.name
  - dynamicSelect > CAMPO <Select CON LAS OPCIONES DINÁMICAS CARGADAS PREVIAMENTE EN options_${field.name} Y CON EL VALOR selected_${field.name} SELECCIONADO
  - controlledDynamicSelect > COMO EL dynamicSelect PERO CON SU VALOR ATADO AL STATE
  - controlledRouteDynamicSelect > COMO EL controlledDynamicSelect PERO CARGA LOS VALORES INICIALES DE UN ENDPOINT DEL BACKEND
  - textarea > CAMPO <Textarea CON POR DEFECTO EL VALOR DE LA VARIABLE DE ESTADO field.name
  - editor > CARGA EL COMPONENTE <TinyEditor PASANDOLE NOMBRE, ID, VALOR, READONLY Y FUNCION onEditorChange
  - image > CARGA EL COMPONENTE InputImage PASANDOLE LA RUTA A LA IMAGEN Y A SU MINIATURA
  - imageFile > CARGA EL COMPONENTE InputImageFile PASANDOLE LA RUTA A LA IMAGEN Y A SU MINIATURA
  - file > CARGA EL COMPONENTE InputFile PASANDOLE SU URL Y SU TÍTULO
  - controlledEmail > CARGA EL COMPONENTE InputEmail 
  - checkbox > CAMPO TIPO <CustomInput type="checkbox" MARCADO O NO SEGÚN EL VALOR POR DEFECTO
  - controlledCheckbox > CAMPO TIPO <CustomInput type="checkbox" MARCADO O NO SEGÚN EL VALOR DE LA VARIABLE DE ESTADO field.name
  - switch > CAMPO TIPO <CustomInput type="switch" MARCADO O NO SEGÚN EL VALOR DE LA VARIABLE DE ESTADO field.name
  - controlledSwitch > COMO EL switch PERO EJECUTA handleChangeOnSwitch
  - list > CARGA EL COMPONENTE Listado CON SUS PROPIEDADES
  - controlledList > COMO EL list PERO CON LA PROPIEDAD updateLists Y updateListForm
  - (NO ACABADO) wizard > UNA GUIA DE PASOS PARA COMPLETAR UN PROCESO

FUNCIONES:

  - componentDidMount > FUNCION QUE LLAMA A LA API EN routeApiControl Y CREA LAS VARIABLES DE ESTADO CORRESPONDIENTES A LOS CAMPOS DEL REGISTRO

  - (*** VER EL RESTO DE FUNCIONES EN EL COMPONENTE DE FUNCIONES ***)

*/

import React from "react";
import { Link, withRouter, Router, Redirect } from 'react-router-dom';
import debounce from "lodash/debounce";
// import _ from 'lodash';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faTrashAlt, faSave, faUndo, faReply, faHistory, faArrowLeft, faCopy, faArrowAltCircleLeft, faFolderOpen} from "@fortawesome/free-regular-svg-icons";
//import { faUndo } from "@fortawesome/fontawesome-common-types";
import { faExclamationCircle, faPaperPlane } from "@fortawesome/free-solid-svg-icons";

// import {
//   AvForm,
//   AvField
//   // AvGroup,
//   AvInput
//   // AvFeedback,
//   // AvRadioGroup,
//   // AvRadio,
//   // AvCheckboxGroup,
//   // AvCheckbox
// } from "availity-reactstrap-validation";
import {
  // Card,
  // CardBody,
  // CardHeader,
  // CardTitle,
  Col,
  // Container,
  Button,
  Label,
  Form,
  FormGroup,
  Input,
  CustomInput,
  Modal,
  // ModalBody,
  // ModalFooter,
  // ModalHeader,
  Spinner,
  UncontrolledAlert,
} from "reactstrap";

import { toastr } from "react-redux-toastr";

// import CKEditor from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

// import { Editor } from "@tinymce/tinymce-react";
import TinyEditor from './TinyEditor';

import Select from "react-select";
import FormList from "./FormList";
import InputFile from "./InputFile";
import InputImage from "./InputImage";
import InputImageFile from "./InputImageFile";
import InputEmail from "./InputEmail";
import ModalWindow from "./ModalWindow";
import { Alert } from "bootstrap";


import WizardComponent from "./WizardComponent";

import {
  modalToggle,
  modalCancel,
  modalConfirm,
  modalAccept,
  handleFileChange,
  handleFileDelete,
  handleImageChange,
  handleImageFileChange,
  handleImageDelete,
  handleImageFileDelete,
  handleDelete,
  handleDuplicate,
  handleSendEmail,
  deleteRegister,
  duplicateRegister,
  sendRegister,
  redirect,
  redirectDuplicateRegister,
  redirectSendRegister,
  continueRegister,
  loadingCompleted,
  updateForm,
  initialState,

  handleValidSubmit,
  handleChange,
  handleKeyDownOnNumber,
  handleKeyDownOnEmail,
  handleChangeOnNumber,
  handleChangeOnSelect,
  handleChangeOnEditor,
  handleChangeOnSwitch,
  handleChangeOnEmail,
  handleErrors,
  showToastr,
  validateEmail,
  validateFields,
  excludeDataFetch,
  // debouncedFetchApi,
  fetchApi,
} from "./Functions";

import jQuery from "jquery";
const $ = jQuery;
window.jQuery = jQuery;

class FormEdit extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      
      // CAMPO DEL TOKEN
      token: localStorage.getItem('token') || sessionStorage.getItem('token'),

      // CAMPOS VENTANA MODAL 
      modalType: "",
      modalTitle: "",
      modalText: "",
      modalIsOpen: false,

      // CAMPOS LOADING
      isLoading: true,
      isLoadingModal: true,

      // CAMPOS ACCIONES 
      action: "",

      // CAMPOS FUNCIONES ELIMINAR
      deleteType: "",
      // numeroEliminar: "",

      // OTROS CAMPOS
      updateLists: false,

      // CAMPOS QUE ESTABAN EN props ANTES
      component: props.component,
      tablename: props.tablename,
      id: props.idValue,
      idParent: props.idParent,
      idField: props.idField,
      deleteField: props.deleteField,
      numberFiles: props.numberFiles,
      numberImages: props.numberImages,
      numberImageFiles: props.numberImageFiles,
      autoSave: props.autoSave,
      updateListForm: props.updateListForm,
      initialFetch: props.initialFetch,
      skipRedirectSubmit: props.skipRedirectSubmit,
      submitButtonText: props.submitButtonText,

      // CAMPOS ERRORES (DEBEN CONTENER "error" AL PRINCIPIO PARA QUE NO SE EXCLUYAN AL ENVIAR A LA API)
      errorValidation: false,
      errorForm: "",
      errorGeneral: false,
      toastrIsOpen: false,

      // ARRAY CON LOS NOMBRES DE LAS VARIABLES A EXCLUIR DEL ENVIO A LA API
      dataExcludeParentComponent: props.dataExcludeParentComponent,
      dataExcludeComponent: [
        "token",
        "modalType",
        "modalType",
        "modalTitle",
        "modalText",
        "modalIsOpen",
        "isLoading",
        "isLoadingModal",
        "updateLists",
        "updateListForm",
        "initialFetch",
        "errorValidation",
        "errorForm",
        "errorGeneral",
        "toastrIsOpen",
        "skipRedirectSubmit",
        "submitButtonText",
        "bt_deleteReadonly",
        "dataExcludeParentComponent",
        "dataExcludeComponent",
        "dataIncludeParentComponent",
        "dataIncludeComponent"
      ],

      dataIncludeParentComponent: props.dataIncludeParentComponent,
      dataIncludeComponent: []
    };

    this.modalToggle = modalToggle.bind(this);
    this.modalCancel = modalCancel.bind(this);
    this.modalConfirm = modalConfirm.bind(this);
    this.modalAccept = modalAccept.bind(this);
    this.handleFileChange = handleFileChange.bind(this);
    this.handleFileDelete = handleFileDelete.bind(this);
    this.handleImageChange = handleImageChange.bind(this);
    this.handleImageFileChange = handleImageFileChange.bind(this);
    this.handleImageDelete = handleImageDelete.bind(this);
    this.handleImageFileDelete = handleImageFileDelete.bind(this);
    this.handleDelete = handleDelete.bind(this);
    this.handleDuplicate = handleDuplicate.bind(this);
    this.handleSendEmail = handleSendEmail.bind(this);
    this.deleteRegister = deleteRegister.bind(this);
    this.duplicateRegister = duplicateRegister.bind(this);
    this.sendRegister = sendRegister.bind(this);
    this.redirect = redirect.bind(this);
    this.redirectDuplicateRegister = redirectDuplicateRegister.bind(this);
    this.redirectSendRegister = redirectSendRegister.bind(this);
    this.continueRegister = continueRegister.bind(this);
    this.loadingCompleted = loadingCompleted.bind(this);
    this.updateForm = updateForm.bind(this);
    this.initialState = initialState.bind(this);
    this.handleValidSubmit = handleValidSubmit.bind(this);
    this.handleChange = handleChange.bind(this);
    // NO FUNCIONA, DA ERROR AL ACCEDER AL event.target.name
    // this.debouncedHandleChange = debounce(this.handleChange, 250);
    this.handleKeyDownOnNumber = handleKeyDownOnNumber.bind(this);
    this.handleKeyDownOnEmail = handleKeyDownOnEmail.bind(this);
    this.handleChangeOnNumber = handleChangeOnNumber.bind(this);
    this.handleChangeOnSelect = handleChangeOnSelect.bind(this);
    this.handleChangeOnEditor = handleChangeOnEditor.bind(this);
    this.handleChangeOnSwitch = handleChangeOnSwitch.bind(this);
    this.handleChangeOnEmail = handleChangeOnEmail.bind(this);
    this.handleErrors = handleErrors.bind(this);
    this.showToastr = showToastr.bind(this);
    this.validateEmail = validateEmail.bind(this);
    this.validateFields = validateFields.bind(this);
    this.excludeDataFetch = excludeDataFetch.bind(this);
    this.fetchApi = fetchApi.bind(this);
    this.debouncedFetchApi = debounce(this.fetchApi, 500);

    this.componentDidMount = this.componentDidMount.bind(this);
    this.handleNext = this.handleNext.bind(this);
  }

  // debouncedFetchApi = debounce( () => {
  //   console.log("HOLA")
  // }, 500);
  

  //FUNCION QUE LLAMA A LA API EN routeApiControl Y CREA LAS VARIABLES DE ESTADO CORRESPONDIENTES A LOS CAMPOS DEL REGISTRO
  async componentDidMount() {

    // alert("componentDidMount");
    // alert(this.state.id)
    // this.setState({ isLoading: true });

    // //////////ESTA ERA LA LLAMADA QUE UTILIZABA ANTES PARA LLAMAR A _genericas, etc..this.fetchApi(`${this.props.routeApiControl}?id=${this.state.id}&tablename=${this.props.tablename}`);
    // this.fetchApi(`${this.props.routeApiControl}?modified_field=id&id=${this.state.id}&id_padre=${this.props.idParent}&tablename=${this.props.tablename}`);

    this.setState({ isLoading: true });
    //alert("DIDMOUNT FORMULARIO");

    await this.initialState();

    ////if (this.props.match.params.id>0) { //TENEMOS QUE DESHABILITARLO PARA AUNQUE SEA UN ALTA CREE LAS VARIABLES EN EL STATE

    // if (this.props.idValue==0) { //INICIALIZAMOS EL MINIMO DE VALORES
    //   this.setState({id: "0"});
    //   this.setState({int_status: "3"});
    // }

    // const sentData = new FormData();
    // this.setState({ isLoadingModal: true });

    // SI NO TIENE ASOCIADO UN id NO MODIFICAMOS action PORQUE ES FORMULARIO DE LOGIN O PUBLICO
    // alert(this.state[`${this.props.idField}`]);
    if (this.state[`${this.props.idField}`]) {
      if (this.state[`${this.props.idField}`] == "0") {
        // alert("CREATE");
        await this.setState({ action: "create" })
      } else {
        // alert("UPDATE");
        await this.setState({ action: "update" })
      }
    }
    
    // const sentData = JSON.stringify(this.state);
    // const sentData = JSON.stringify(this.excludeDataFetch());
    let sentData = new FormData();
    let state = JSON.stringify(this.excludeDataFetch());
    sentData.append( "state", state );
    // console.log(sentData);

    /*****VAMOS A CARGAR LAS OPCIONES DE LOS SELECTS DINAMICOS*****/
    const dynamicFields = this.props.fields.filter((field) => (field.type === "dynamicSelect" || field.type === "controlledRouteDynamicSelect"));

    if (dynamicFields.length>0) {

      let counter = 0;

      await dynamicFields.map((field, index) => {

        //>>>>>>>>AQUI HABRA QUE INTRODUCIR EL ID EN EL BODY DEL POST PARA CUANDO EL DESPLEGABLE DEPENDE DE UN IDPADRE <<<<<<<
        fetch(`${field.routeApi}` , {
          method: "POST",
          body: sentData,
          headers: { 
            "Token": `${this.state.token}`,
            // "Content-Type": "application/json",
            "Accept": "application/json, text-plain, */*"
          }
        }).then(receivedData => {
          return receivedData.json();
        }).then(receivedData => {

          // console.log("CARGO OPCIONES EN " + `options_${field.name}` + " - INDEX: " + index);
          //alert("CARGO OPCIONES EN " + `options_${field.name}` + " - INDEX: " + index);
          //field.options = receivedData;
          this.setState({ [`options_${field.name}`] : receivedData });

        }).then(() => {        

          counter++;

          // ESTO NO HACE FALTA POR EL CAMBIO DE ORDEN ENTRE SELECTS DINAMICOS Y EL RESTO DE SETSTATES
          // if (counter===dynamicFields.length) { //Si es el último ponemos el semáforo en verde
          //   this.setState({isLoading: false});
          //   this.setState({isLoadingModal: false});
          // }
          
        }).catch(error => console.log(error));
      });
    }

    //console.log(receivedData);

    if (this.state.initialFetch) {

      //CARGAMOS LOS DATOS DESDE LA API
      // alert(`${this.props.routeApiControl}?id=${this.props.idValue}`)
      await fetch(`${this.props.routeApiControl}?modified_field=id` , {
        method: "POST",
        body: sentData,
        headers: { 
          "Token": `${this.state.token}`,
          // "Content-Type": "application/json",
          "Accept": "application/json, text-plain, */*"
        }
      }).then(receivedData => {
        return receivedData.json();
      }).then(receivedData => {

        // this.setState({receivedData: receivedData});
        // console.log(receivedData);

        if (receivedData.length>0) {

          receivedData.map((data) => { 
            
            /**************ASIGNAMOS LOS VALORES DE LOS STATES DE LOS CAMPOS ***********/
            Object.entries(data).map(([key,value]) => {  
                        
              // HACEMOS UNA RECONVERSION PASANDO A CADENA Y OTRA VEZ A OBJETO PARA ASEGURARNOS DE QUE COGE LA FORMA DE OBJETO, PUES DABA ERROR EN LOS JSON ANIDADOS COMO LOS DE options_rendimiento, etc....
              let obj = JSON.parse(JSON.stringify(value));
              this.setState({ [`${key}`] : obj });
              // console.log("AL STATE:: " + `${key}` + ":" + obj );
            })
            
            /**************ASIGNAMOS LOS VALORES DE LOS STATES DE ARCHIVOS ***********/
            for (let i=1; i<=this.props.numberFiles; i++) {
              if (data[`url${i}`]) {
                this.setState({ [`url${i}`] : data[`url${i}`] });
                //this.setState({ [`route${i}`] : process.env.REACT_APP_API_DOMAIN+data[`url${i}`] }); //ASI SI QUEREMOS AÑADIR EL DOMINIO
                this.setState({ [`route${i}`] : data[`url${i}`] }); //ASI SIN DOMINIO
              } else {
                this.setState({ [`route${i}`] : "" });
              }  
              if (data[`titulo${i}`]) { 
                this.setState({ [`title${i}`] : data[`titulo${i}`] })
              } else {
                this.setState({ [`title${i}`] : "" })
              }
            }
              
            /********************VAMOS CON LAS IMAGENES ************************/          
            for (let i=1; i<=this.props.numberImages; i++) {
              if (data[`available_image${i}`] === "1") {
                this.setState({ [`availableImage${i}`] : data[`available_image${i}`] });
                //this.setState({ [`routeImage${i}`] : `${process.env.REACT_APP_API_DOMAIN}/get_img.php?id=${data.id}&n_img=${i}&tablename=img_${this.props.tablename}` }); //ASI SI QUEREMOS AÑADIR EL DOMINIO
                this.setState({ [`routeImage${i}`] : `/get_img.php?id=${data.id}&n_img=${i}&tablename=img_${this.props.tablename}` }); //ASI SIN DOMINO
                this.setState({ [`routeThumbnail${i}`] : `${process.env.REACT_APP_API_DOMAIN}/get_img.php?id=${data.id}&n_img=${i}&tablename=img_${this.props.tablename}` });
              } else {
                this.setState({ [`availableImage${i}`] : "" });
                this.setState({ [`routeImage${i}`] : "" });
                this.setState({ [`routeThumbnail${i}`] : `${process.env.REACT_APP_API_DOMAIN}/${process.env.REACT_APP_NON_AVAILABLE_IMAGE}` });
              }
            }

            /************* VAMOS CON LAS IMAGENES TIPO ARCHIVO ******************/          
            for (let i=1; i<=this.props.numberImageFiles; i++) {
              if ((data[`imagen${i}`]) && (data[`imagen${i}`] !== "")) {
                this.setState({ [`availableImage${i}`] : "1" });
                // this.setState({ [`routeImage${i}`] : `${process.env.REACT_APP_API_DOMAIN}/` + data[`imagen${i}`] }); //ASI SI QUEREMOS AÑADIR EL DOMINIO
                this.setState({ [`routeImage${i}`] : data[`imagen${i}`] }); 
                // this.setState({ [`routeThumbnail${i}`] : `${process.env.REACT_APP_API_DOMAIN}/` + data[`imagen${i}`] }); //ASI SI QUEREMOS AÑADIR EL DOMINIO
                this.setState({ [`routeThumbnail${i}`] : data[`imagen${i}`] });
              } else {
                this.setState({ [`availableImage${i}`] : "" });
                this.setState({ [`routeImage${i}`] : "" });
                this.setState({ [`routeThumbnail${i}`] : `${process.env.REACT_APP_API_DOMAIN}/${process.env.REACT_APP_NON_AVAILABLE_IMAGE}` });
              }
            }
          })

        }
        ////this.setState({isLoading: false}); //NO LO HACEMOS AQUI POR SI HAY selectsDinamicos
      }).then(() => {

        /*****VAMOS A CARGAR LOS SELECTED DE LOS SELECTS DINAMICOS*****/
        const dynamicFields = this.props.fields.filter((field) => (field.type === "dynamicSelect" || field.type === "controlledRouteDynamicSelect"));

        if (dynamicFields.length>0) {

          // console.log("dynamicFields.length: " + dynamicFields.length);

          let counter=0;

          dynamicFields.map((field,index) => {

            // console.log("field: " + `${field.name}`);

            //>>>>>>>>AQUI HABRA QUE INTRODUCIR EL ID EN EL BODY DEL POST PARA CUANDO EL DESPLEGABLE DEPENDE DE UN IDPADRE <<<<<<<
            fetch(`${field.routeApi}` , {
              method: "POST",
              body: sentData,
              headers: { 
                "Token": `${this.state.token}`,
                // "Content-Type": "application/json",
                "Accept": "application/json, text-plain, */*"
              }
            }).then(receivedData => {
              return receivedData.json();
            }).then(receivedData => {

              // console.log("CARGO OPCIONES EN " + `options_${field.name}` + " - INDEX: " + index);
              //alert("CARGO OPCIONES EN " + `options_${field.name}` + " - INDEX: " + index);
              //field.options = receivedData;
              this.setState({ [`options_${field.name}`] : receivedData });

            }).then(() => {        

              counter++;

              //alert("LENGTH: " + this.state[`options_${field.name}`].length);
              // console.log("optionsArray.length: " + this.state[`options_${field.name}`].length);
              let optionsArray = [];
              optionsArray = this.state[`options_${field.name}`];
              //alert("LENGTH: " + optionsArray.length);
              //alert("BUSCO: " + this.state[`${field.name}`]);
              //this.setState({ [`selected_${field.name}`] : optionsArray.filter(obj => obj.value === this.state[`${field.name}`]) });
              let selectedArray = [];
              if (this.state[`${field.name}`]) {
                selectedArray = optionsArray.find(x => x.value === this.state[`${field.name}`]);
              } else {
                selectedArray = {"value": "", "label": ""}
              }
              //alert("POSICION: " + posicion_selected)
              this.setState({ [`selected_${field.name}`] : selectedArray });
              //alert(this.state[`selected_${field.name}`]);

              // SI ES EL ÚLTIMO LA CARGA SE HA COMPLETADO
              if (counter === dynamicFields.length) { 
                this.setState({isLoading: false});
                this.setState({isLoadingModal: false});
                this.loadingCompleted();
              }
              
            }).catch(error => console.log(error));
          });

        } else {
          // console.log("LOADINGCOMPLETED!!!!");
          this.loadingCompleted();
        }

      }).catch(error => console.log(error));
          
      /*} else { //del if (this.props.idValue>0)
        this.setState({id: 0});
        this.setState({int_status: 3});
        this.setState({isLoading: false});
      }*/

      // if (this.state.errorGeneral) {
      //   localStorage.clear();
      //   sessionStorage.clear();
      //   this.props.history.push("/");
      //   window.location.reload();
      // }

      // if (this.state.errorForm != "") {
      //   this.setState({isLoading: false});
      //   this.setState({isLoadingModal: false});
      //   this.showToastr("Error", this.state.errorForm);
      // }
          
    } else { // SI NO HAY initialFetch CARGAMOS LOS SELECTS DINAMICOS SI HAY

      const dynamicFields = this.props.fields.filter((field) => (field.type === "dynamicSelect" || field.type === "controlledRouteDynamicSelect"));

      if (dynamicFields.length>0) {

        // console.log("dynamicFields.length: " + dynamicFields.length);

        let counter=0;

        dynamicFields.map((field,index) => {

          // console.log("field: " + `${field.name}`);

          //>>>>>>>>AQUI HABRA QUE INTRODUCIR EL ID EN EL BODY DEL POST PARA CUANDO EL DESPLEGABLE DEPENDE DE UN IDPADRE <<<<<<<
          fetch(`${field.routeApi}` , {
            method: "POST",
            body: sentData,
            headers: { 
              "Token": `${this.state.token}`,
              // "Content-Type": "application/json",
              "Accept": "application/json, text-plain, */*"
            }
          }).then(receivedData => {
            return receivedData.json();
          }).then(receivedData => {

            // console.log("CARGO OPCIONES EN " + `options_${field.name}` + " - INDEX: " + index);
            //alert("CARGO OPCIONES EN " + `options_${field.name}` + " - INDEX: " + index);
            //field.options = receivedData;
            this.setState({ [`options_${field.name}`] : receivedData });

          }).then(() => {        

            this.loadingCompleted();

          }).catch(error => console.log(error));
        });
      } else {
        this.loadingCompleted();
      }
    }

    this.handleErrors();

  }
  
  async handleNext() {
      // Navigate next
      alert("LLEGO!");
      $('#smartwizard').smartWizard("next");
      // return false;
  }
  
  render () {

    const { isLoading, modalType, modalTitle, modalText, modalIsOpen, isLoadingModal } = this.state;

    //if ((isLoading) || (!dataFetch.length)) { //EL isLoading SOLO NO FUNCIONA
    if (isLoading) { 
      return (
        <Modal modalIsOpen={isLoadingModal} centered >
          {/* <Spinner animation="grow" /> */}
        </Modal>
      )
    } else {    
      //alert("RENDERIZO FORMULARIO");
      return (
        <div>
          <Form key={0} onSubmit={this.handleValidSubmit} lang="en" >
            { this.props.fields.map((field, index) => {
              //this.renderCampo(field, index)
              switch (field.type) {

                case "parent":
                  return (                   
                    <FormGroup key={index} row hidden={field.hidden}>
                      <Label for={field.name} sm={2}>{field.label}</Label>
                      <Col sm={10}>
                        <Input
                          type="text"
                          name={field.name}
                          id={field.name}
                          defaultValue={this.props.idParent}
                          readOnly={field.readOnly}
                        />
                      </Col>
                    </FormGroup>
                  );

                  case "controlledParent":
                    return (                   
                      <FormGroup key={index} row hidden={this.state[`${field.name}Hidden`]}>
                        <Label for={field.name} sm={2}>{field.label}</Label>
                        <Col sm={10}>
                          <Input
                            type="text"
                            name={field.name}
                            id={field.name}
                            value={this.props.idParent}
                            readOnly={this.state[`${field.name}ReadOnly`]}
                            onChange={this.handleChange}
                            // required={this.state[`${field.required}`]}
                          />
                          <Label className="text-danger" hidden={!this.state[`${field.name}Error`]} >
                            {this.state[`${field.name}Error`]}
                          </Label>
                        </Col>
                      </FormGroup>
                    );
  
                case "text":
                  return (                   
                    <FormGroup key={index} row hidden={field.hidden}>
                      <Label for={field.name} sm={2}>{field.label}</Label>
                      <Col sm={10}>
                        <Input
                          type="text"
                          name={field.name}
                          id={field.name}
                          defaultValue={this.state[`${field.name}`]}
                          readOnly={field.readOnly}
                        />
                      </Col>
                    </FormGroup>
                  );

                case "controlledText":
                  return (                   
                    <FormGroup key={index} row hidden={this.state[`${field.name}Hidden`]}>
                      <Label for={field.name} sm={2}>{field.label}</Label>
                      <Col sm={10}>
                        <Input
                          type="text"
                          name={field.name}
                          id={field.name}
                          value={this.state[`${field.name}`]}
                          readOnly={this.state[`${field.name}ReadOnly`]}
                          // readOnly={this.state[`${field.name}ReadOnly`]}
                          onChange={this.handleChange}
                          // required={this.state[`${field.required}`]}
                        />
                        <Label className="text-danger" hidden={!this.state[`${field.name}Error`]} >
                          {this.state[`${field.name}Error`]}
                        </Label>
                      </Col>
                    </FormGroup>
                  );

                case "controlledNumber":
                  return (                   
                    <FormGroup key={index} row hidden={this.state[`${field.name}Hidden`]}>
                      <Label for={field.name} sm={2}>{field.label}</Label>
                      <Col sm={10}>
                        <Input
                          type="text"
                          // lang="en"
                          // inputmode="numeric"
                          // pattern="[0-9]{0.5}"
                          // pattern="\d+(\.\d{2})?"
                          // pattern="^-?[0-9]\d*([\.,]?\d{0,2})"
                          // pattern="^-?[0-9]\d*([\.{0,1},{0,1}]?\d{0,2})"
                          // pattern="^-?[0-9]\d*([\.,]{0,1}?\d{0,2})"
                          pattern="^-?[0-9]\d*([\.]{0,1}?\d{0,2})"
                          step="0.01"
                          inputMode="decimal"
                          // min="0"
                          name={field.name}
                          id={field.name}
                          value={this.state[`${field.name}`]}
                          // readOnly={field.readOnly}
                          readOnly={this.state[`${field.name}ReadOnly`]}
                          onKeyDown={this.handleKeyDownOnNumber}
                          onChange={this.handleChangeOnNumber}
                          // oninvalid={() => { alert("Inválido!")}}
                          // onKeyPress={this.handleKeyDownOnNumber}
                          // onKeyUp={this.handleKeyDownOnNumber}
                          // required={this.state[`${field.required}`]}
                        />
                        <Label className="text-danger" hidden={!this.state[`${field.name}Error`]} >
                          {this.state[`${field.name}Error`]}
                        </Label>
                      </Col>
                    </FormGroup>
                  );
  
                case "controlledInformative":
                  return (                   
                    <FormGroup key={index} row hidden={this.props.informativeHidden}>
                      <Label for={field.name} sm={2}>{field.label}</Label>
                      <Col sm={10}>
                        <Input
                          type="text"
                          name={field.name}
                          id={field.name}
                          value={this.state[`${field.name}`]}
                          readOnly={field.readOnly}
                          onChange={this.handleChange}
                        />
                      </Col>
                    </FormGroup>
                  );
                
                case "controlledPassword":
                  return (                   
                    <FormGroup key={index} hidden={this.state[`${field.name}Hidden`]}>
                      <Label for={field.name}>{field.label}</Label>
                      <Input
                        bsSize="lg"
                        type="password"
                        name={field.name}
                        placeholder={field.placeholder}
                        id={field.name}
                        value={this.state[`${field.name}`]}
                        readOnly={field.readOnly}
                        onChange={this.handleChange}
                      />
                      <Label className="text-danger" hidden={!this.state[`${field.name}Error`]} >
                        {this.state[`${field.name}Error`]}
                      </Label>
                    </FormGroup>
                  );
    
                case "select":
                  return (                   
                    <FormGroup key={index} row>
                      <Label for={field.name} sm={2}>{field.label}</Label>
                      <Col sm={10}>
                        <Select
                          name = {field.name}
                          id = {field.name}
                          //forwardref = {field.name}
                          className = "react-select-container"
                          classNamePrefix = "react-select"
                          options = {field.options}
                          defaultValue={field.options.find(obj => obj.value === this.state[`${field.name}`])}
                          selected={this.state[`${field.name}`]}
                          isSearchable
                        />
                      </Col>
                    </FormGroup>
                  );

                  case "dynamicSelect":
                    return (                   
                      <FormGroup key={index} row>
                        <Label for={field.name} sm={2}>{field.label}</Label>
                        <Col sm={10}>
                          <Select
                            name = {field.name}
                            id = {field.name}
                            //forwardref = {field.name}
                            className = "react-select-container"
                            classNamePrefix = "react-select"
                            options = {this.state[`options_${field.name}`]}
                            defaultValue = {this.state[`selected_${field.name}`]}
                            selected = {this.state[`${field.name}`]}
                            isDisabled={ field.readOnly }
                            // isClearable={!field.readOnly}
                            // isSearchable={!field.readOnly}
                            // openMenuOnClick={!field.readOnly}
                            isSearchable
                          />
                        </Col>
                      </FormGroup>
                    );

                  //ESTE TIPO VIENE DEL dynamicSelectConFuncion DE CALCULITH
                  case "controlledDynamicSelect":
                    return (                   
                      <FormGroup key={index} row hidden={this.state[`${field.name}Hidden`]}>
                        <Label for={field.name} sm={2}>{field.label}</Label>
                        <Col sm={10}>
                          <Select
                            name = {field.name}
                            id = {field.name}
                            //forwardref = {field.name}
                            className = "react-select-container"
                            classNamePrefix = "react-select"
                            options = {this.state[`options_${field.name}`]}
                            value = {this.state[`selected_${field.name}`]}
                            // onChange = {this[`${field.functionOnChange}`]}
                            // onChange = {this[`${field.functionOnChange}`]}
                            onChange = {(selectedOption) => this.handleChangeOnSelect(selectedOption,field.name)}
                            selected = {this.state[`${field.name}`]}
                            isDisabled = {this.state[`${field.name}ReadOnly`]}
                            // isClearable={!field.readOnly}
                            // isSearchable={!field.readOnly}
                            // openMenuOnClick={!field.readOnly}
                            isSearchable
                            isClearable
                          />
                          <Label className="text-danger" hidden={!this.state[`${field.name}Error`]} >
                            {this.state[`${field.name}Error`]}
                          </Label>
                        </Col>
                      </FormGroup>
                    );

                  case "controlledRouteDynamicSelect":
                    return (                   
                      <FormGroup key={index} row hidden={this.state[`${field.name}Hidden`]}>
                        <Label for={field.name} sm={2}>{field.label}</Label>
                        <Col sm={10}>
                          <Select
                            name = {field.name}
                            id = {field.name}
                            //forwardref = {field.name}
                            className = "react-select-container"
                            classNamePrefix = "react-select"
                            options = {this.state[`options_${field.name}`]}
                            value = {this.state[`selected_${field.name}`]}
                            // onChange = {this[`${field.functionOnChange}`]}
                            // onChange = {this[`${field.functionOnChange}`]}
                            onChange = {(selectedOption) => this.handleChangeOnSelect(selectedOption,field.name)}
                            selected = {this.state[`${field.name}`]}
                            isDisabled = {field.readOnly}
                            // isClearable={!field.readOnly}
                            // isSearchable={!field.readOnly}
                            // openMenuOnClick={!field.readOnly}
                            isSearchable
                            isClearable
                          />
                          <Label className="text-danger" hidden={!this.state[`${field.name}Error`]} >
                            {this.state[`${field.name}Error`]}
                          </Label>
                        </Col>
                      </FormGroup>
                    );

                case "textarea":
                  return (
                    <FormGroup key={index} row hidden={field.hidden}>
                      <Label for={field.name} sm={2}>{field.label}</Label>
                      <Col sm={10}>
                        <Input
                          type="textarea"
                          name={field.name}
                          id={field.name}
                          style={{height: 100}}
                          defaultValue={this.state[`${field.name}`]}
                          readOnly={field.readOnly}
                        />
                      </Col>
                    </FormGroup>
                  );

                case "controlledTextarea":
                  return (
                    <FormGroup key={index} row hidden={field.hidden}>
                      <Label for={field.name} sm={2}>{field.label}</Label>
                      <Col sm={10}>
                        <Input
                          type="textarea"
                          name={field.name}
                          id={field.name}
                          style={{height: 100}}
                          value={this.state[`${field.name}`]}
                          readOnly={field.readOnly}
                          onChange={this.handleChange}
                        />
                      </Col>
                    </FormGroup>
                  );
  
  
                // //LOS CAMPOS DE TIPO editor SIEMPRE HAN SIDO CONTROLADOS
                // case "editor":
                //   return (
                //     <FormGroup key={index} row hidden={field.hidden}>
                //       <Label for={field.name} sm={2}>{field.label}</Label>
                //       <Col sm={10}>
                //         <Editor 
                //           name={field.name} 
                //           id={field.name}
                //           // apiKey="javneu3upobzaafer7elni6vp4c4qv0h2cer6x6n98m5cgle" 
                //           onEditorChange={this.handleChangeOnEditor} 
                //           value={this.state[`${field.name}`]}
                //           init={{
                //             height: 300,
                //             menubar: true,
                //             plugins: [
                //               'advlist autolink lists link image charmap print preview anchor',
                //               'searchreplace visualblocks code fullscreen',
                //               'insertdatetime media table paste code help wordcount'
                //             ],
                //             toolbar:
                //             'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help',
                //             powerpaste_allow_local_images: true,
                //             image_advtab: true,
                //           }}                         
                //           readOnly={field.readOnly} 
                //         />
                //         <Label className="text-danger" hidden={!this.state[`${field.name}Error`]} >
                //           {this.state[`${field.name}Error`]}
                //         </Label>
                //       </Col>
                //     </FormGroup>
                //   );

                //LOS CAMPOS DE TIPO editor SIEMPRE HAN SIDO CONTROLADOS
                case "editor":
                  return (
                    <FormGroup key={index} row hidden={field.hidden}>
                      <Label for={field.name} sm={2}>{field.label}</Label>
                      <Col sm={10}>
                        <TinyEditor
                          name={field.name} 
                          id={field.name} 
                          onEditorChange={this.handleChangeOnEditor} 
                          value={this.state[`${field.name}`]}
                          // init={{
                          //   height: 300,
                          //   menubar: true,
                          //   plugins: [
                          //     'advlist autolink lists link image charmap print preview anchor',
                          //     'searchreplace visualblocks code fullscreen',
                          //     'insertdatetime media table paste code help wordcount'
                          //   ],
                          //   toolbar:
                          //   'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help',
                          //   powerpaste_allow_local_images: true,
                          //   image_advtab: true,
                          // }}                         
                          readOnly={field.readOnly} 
                        />
                        <Label className="text-danger" hidden={!this.state[`${field.name}Error`]} >
                          {this.state[`${field.name}Error`]}
                        </Label>
                      </Col>
                    </FormGroup>
                  );

                case "image":
                  return (
                    <FormGroup key={index} row hidden={field.hidden}>
                      <Label for={field.name} sm={2}>{field.label}</Label>
                      <Col sm={10}>
                        <InputImage
                          id={field.imageNumber}
                          idParent={this.state.id}
                          tablename={`img_${this.props.tablename}`}
                          availableImage={this.state[`availableImage${field.imageNumber}`]}
                          handleImageChange={this.handleImageChange}
                          handleImageDelete={this.handleImageDelete}
                          name={field.name}
                          routeImage={this.state[`routeImage${field.imageNumber}`]}
                          routeThumbnail={this.state[`routeThumbnail${field.imageNumber}`]}
                          readOnly={field.readOnly}
                        />
                      </Col>
                    </FormGroup>
                  );

                case "imageFile":
                  return (
                    <FormGroup key={index} row hidden={field.hidden}>
                      <Label for={field.name} sm={2}>{field.label}</Label>
                      <Col sm={10}>
                        <InputImageFile
                          id={field.imageNumber}
                          idParent={this.state.id}
                          tablename={`img_${this.props.tablename}`}
                          availableImage={this.state[`availableImage${field.imageNumber}`]}
                          handleImageChange={this.handleImageFileChange}
                          handleImageDelete={this.handleImageFileDelete}
                          name={field.name}
                          routeImage={this.state[`routeImage${field.imageNumber}`]}
                          routeThumbnail={this.state[`routeThumbnail${field.imageNumber}`]}
                          readOnly={field.readOnly}
                          routeHidden={field.routeHidden}
                        />
                      </Col>
                    </FormGroup>
                  );
  
                case "file":
                  return (
                    <FormGroup key={index} row hidden={field.hidden}>
                      <Label for={field.name} sm={2}>{field.label}</Label>
                      <Col sm={10}>
                        <InputFile
                          id={field.fileNumber}
                          url={this.state[`url${field.fileNumber}`]}
                          titleInputName={`title${field.fileNumber}`}
                          withTitle={field.withTitle}
                          title={this.state[`title${field.fileNumber}`]}
                          handleFileChange={this.handleFileChange}
                          handleFileDelete={this.handleFileDelete}
                          name={field.name}
                          route={this.state[`route${field.fileNumber}`]}
                          readOnly={field.readOnly}
                        />
                      </Col>
                    </FormGroup>
                  );

                case "controlledEmail":
                  return (                   
                    <FormGroup key={index} row hidden={this.state[`${field.name}Hidden`]}>
                      <Label for={field.name} sm={2}>{field.label}</Label>
                      <Col sm={10}>
                        <InputEmail
                          id={field.name}
                          name={field.name}
                          value={this.state[`${field.name}`]}
                          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                          handleKeyDownOnEmail={this.handleKeyDownOnEmail}
                          handleChangeOnEmail={this.handleChangeOnEmail}
                          handleSendEmail={this.handleSendEmail}
                          readOnly={field.readOnly}
                          options={field.options}
                          emailError={this.state[`${field.name}Error`]}
                        />
                      </Col>
                    </FormGroup>
                  );
                
                case "checkbox":
                  return (
                    <FormGroup key={index} hidden={field.hidden}>
                      <CustomInput
                        type="checkbox"
                        id={field.name}
                        name={field.name}
                        label={field.label}
                        defaultChecked={field.defaultValue}
                        onChange={e =>e.target.checked ? this.setState({ [`${field.name}`]: true }) : this.setState({ [`${field.name}`]: false })}
                        disabled={field.readOnly}
                      />
                      <Label className="text-danger" hidden={!this.state[`${field.name}Error`]} >
                        {this.state[`${field.name}Error`]}
                      </Label>
                    </FormGroup>
                  );

                case "controlledCheckbox":
                  return (
                    <FormGroup key={index} hidden={field.hidden}>
                      <CustomInput
                        type="checkbox"
                        id={field.name}
                        name={field.name}
                        label={field.label}
                        defaultChecked={field.defaultValue}
                        checked={this.state[`${field.name}`]}
                        onChange={this.handleChangeOnCheckbox}
                        disabled={field.readOnly}
                      />
                      <Label className="text-danger" hidden={!this.state[`${field.name}Error`]} >
                        {this.state[`${field.name}Error`]}
                      </Label>
                    </FormGroup>
                  );

                case "switch":
                  return (
                    <FormGroup key={index} row hidden={field.hidden}>
                      <Label for={field.name} sm={2}>{field.label}</Label>
                      <Col sm={10}>
                        <CustomInput
                          type="switch"
                          // className="custom-switch"
                          id={field.name}
                          name={field.name}
                          // forwardref={field.name}
                          label=""
                          checked={ (this.state[`${field.name}`] == 1) ? true : false }
                          onChange={ e => e.target.checked ? this.setState({ [`${field.name}`]: 1 }) : this.setState({ [`${field.name}`]: 0 })}
                          disabled={field.readOnly}
                        />
                      </Col>
                    </FormGroup>
                  );

                case "controlledSwitch":
                  return (
                    <FormGroup key={index} row hidden={this.state[`${field.name}Hidden`]}>
                      <Label for={field.name} sm={2}>{field.label}</Label>
                      <Col sm={10}>
                        <CustomInput
                          type="switch"
                          // className="custom-switch"
                          id={field.name}
                          name={field.name}
                          // forwardref={field.name}
                          label=""
                          checked={ (this.state[`${field.name}`] == 1) ? true : false }
                          onChange={this.handleChangeOnSwitch}
                          // disabled={field.readOnly}
                          disabled={this.state[`${field.name}ReadOnly`]}
                        />
                        <Label className="text-danger" hidden={!this.state[`${field.name}Error`]} >
                          {this.state[`${field.name}Error`]}
                        </Label>
                      </Col>
                    </FormGroup>
                  );

                case "list":
                  return (
                    <FormGroup key={index} row hidden={field.hidden}>
                      <Label for="densidad" sm={12}><strong>{field.label}</strong></Label>
                      <Col sm={12}>
                      {/* //SI ES READONLY O ES UN ALTA DE REGISTRO CREAMOS LOS SUBLISTADOS READONLY */}
                        <FormList 
                          component={field.component}
                          tablename={field.tablename} 
                          routeList={`${field.routeList}${this.state.id}/`}
                          columns={field.columns}
                          routeApiList={`${field.routeApiList}`}
                          routeApiActions={field.routeApiActions}
                          rowOptions={field.rowOptions}
                          readOnly={field.readOnly}
                          idParent={this.state.id}
                          idField={field.idField}
                          deleteField={field.deleteField}
                          numberFiles={field.numberFiles}
                          numberImages={field.numberImages}
                          optionsIfField={field.optionsIfField}
                          pagination={field.pagination}
                        /> 
                      </Col>
                    </FormGroup>                
                  );

                case "controlledList":
                  return (
                    <FormGroup key={index} row hidden={field.hidden}>
                      <Label for="densidad" sm={12}><strong>{field.label}</strong></Label>
                      <Col sm={12}>
                      {/* //SI ES READONLY O ES UN ALTA DE REGISTRO CREAMOS LOS SUBLISTADOS READONLY */}
                        <FormList
                          component={field.component}
                          tablename={field.tablename}
                          routeList={`${field.routeList}${this.state.id}/`}
                          columns={field.columns}
                          routeApiList={`${field.routeApiList}`}
                          routeApiActions={field.routeApiActions}
                          rowOptions={field.rowOptions}
                          readOnly={field.readOnly}
                          idParent={this.state.id}
                          idField={field.idField}
                          deleteField={field.deleteField}
                          numberFiles={field.numberFiles}
                          numberImages={field.numberImages}
                          optionsIfField={field.optionsIfField}
                          updateLists={this.state.updateLists}
                          updateListForm={ ((this.props.updateListForm)) ? this.updateForm : null }
                          pagination={field.pagination}
                        /> 
                      </Col>
                    </FormGroup>                
                  );

                // ESTO NO ESTA ACABADO, SE MUESTRA PERO FALTA HACER LOS STEPS DINAMICOS Y LAS LLAMADAS A NEXT, PREVIOS, SUBMIT, FINISH...
                // case "wizard":
                //   return (
                //     // <FormGroup key={index} hidden={this.state[`${field.name}.hidden`]} row>
                //     <FormGroup key={index} hidden={field.hidden} row>
                //       <Col sm={2}>
                //         <Label sm={12}></Label>
                //       </Col>
                //       <Col sm={10} row>
                //         <WizardComponent variant="arrows" color="primary" />
                //       </Col>
                //     </FormGroup>
                //   );
                    
                default:
                  return;
              }
            }
            )}

            <FormGroup row>
              <Col sm={{ size: 8, offset: 2 }}>
                <UncontrolledAlert
                  color="danger"
                  className="alert-outline-coloured my-3"
                  isOpen={this.state.errorForm}
                  toggle=""
                  // key={301}
                >
                  <div className="alert-icon">
                    <FontAwesomeIcon icon={faExclamationCircle} fixedWidth />
                  </div>
                  <div className="alert-message">
                    {this.state.errorForm}
                  </div>
                </UncontrolledAlert>
              </Col>
            </FormGroup>

            <FormGroup row>
              <Col sm={{ size: 10, offset: 2 }}>
                { ((!(this.props.readOnly)) && ((!(this.props.autoSave)) || (this.state.id === "0")) && (this.props.options.indexOf("bt_save") !== -1)) ? (
                  <Button name="bt_save" color="primary" type="submit" className="mr-1 mt-3 mb-1" >
                    <FontAwesomeIcon icon={faSave} /> { this.state["submitButtonText"] ? this.state["submitButtonText"] : "Guardar" }
                  </Button>
                ) : null }
                { (this.props.options.indexOf("bt_back") !== -1) ? (
                  <Button name="bt_back" color="success" className="mr-1 mt-3 mb-1" tag={Link} to={this.props.routeList} >
                    <FontAwesomeIcon icon={faArrowAltCircleLeft} /> Volver
                  </Button>
                ) : null }
                { ((!(this.props.readOnly)) && (this.props.options.indexOf("bt_duplicate") !== -1) && (this.state.id != 0)) ? (
                  <Button name="bt_duplicate" color="warning" className="mr-1 mt-3 mb-1" tag={Link} to="/#" onClick={this.handleDuplicate} >
                    <FontAwesomeIcon icon={faCopy} /> Duplicar
                  </Button>
                ) : null }
                {/* <Button name="bt_back" color="success" className="mr-1 mt-3 mb-1" tag={Link} to="/#" onClick={() => this.props.history.goBack()}>
                  <FontAwesomeIcon icon={faFile} /> Volver al listado
                </Button> */}
                { ((!(this.props.readOnly)) && (this.props.options.indexOf("bt_delete") !== -1)) ? (
                  <Button name="bt_delete" color="danger" className="mr-1 mt-3 mb-1" tag={Link} to="/#" onClick={this.handleDelete} disabled={this.state.bt_deleteReadOnly}>
                    <FontAwesomeIcon icon={faTrashAlt} /> Eliminar
                  </Button>
                ) : null }
                {/* PRUEBA DE WIZARD
                <Button name="bt_next" color="warning" className="mr-1 mt-3 mb-1" onClick={this.handleNext} >
                  <FontAwesomeIcon icon={faCopy} /> Next
                </Button> */}
              </Col>
            </FormGroup>
          </Form>
          <ModalWindow modalType={modalType} modalTitle={modalTitle} modalText={modalText} isOpen={modalIsOpen} modalToggle={this.modalToggle} modalConfirm={this.modalConfirm} modalCancel={this.modalCancel} modalAccept={this.modalAccept} />
          <Modal isOpen={isLoadingModal} centered >
            {/* <Spinner animation="grow" /> */}
          </Modal>
        </div>

     )
    } 
  }
}

//export default Formulario;
export default withRouter(FormEdit);
