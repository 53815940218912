import React from "react";

// Import TinyMce
import "tinymce";

// Default Icons
import "tinymce/icons/default";

// Theme
import "tinymce/themes/silver";

// Plugins
import "tinymce/plugins/advlist";
import "tinymce/plugins/autolink";
import "tinymce/plugins/lists";
import "tinymce/plugins/link";
import "tinymce/plugins/image";
import "tinymce/plugins/charmap";
import "tinymce/plugins/print";
import "tinymce/plugins/preview";
import "tinymce/plugins/anchor";
import "tinymce/plugins/searchreplace";
import "tinymce/plugins/visualblocks";
import "tinymce/plugins/code";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/insertdatetime";
import "tinymce/plugins/media";
import "tinymce/plugins/table";
import "tinymce/plugins/paste";
import "tinymce/plugins/code";
import "tinymce/plugins/help";
import "tinymce/plugins/wordcount";

// // CSS
import "tinymce/skins/ui/oxide/skin.min.css";
// // import "tinymce/skins/content/default/content.min.css";

// // import contentCss from '!!raw-loader!tinymce/skins/content/default/content.min.css';
// // import contentUiCss from '!!raw-loader!tinymce/skins/ui/oxide/content.min.css';

// // *** FUNCIONA!!! ***
// // import contentCss from "tinymce/skins/content/CUSTOM/content.min.css";
// import contentUiCss from "tinymce/skins/ui/CUSTOM/skin.min.css";

// // *** PRUEBAS ***
// import contentUiCss from "tinymce/skins/ui/oxide/skin.min.css";
// // import contentCss from "tinymce/skins/content/default/content.min.css";

import { Editor } from "@tinymce/tinymce-react";
// import { Editor } from "tinymce/tinymce";


export default function TinyEditor(props) {
  return (
    <Editor
      init={{

        skin: false, // skin is loaded manually above as an import
        content_css: false, // loaded manually directly below
        // // content_style: [contentCss, contentUiCss].join('\n'),
        // content_style: contentCss,

        // // *** PARA ESTO HAY QUE COPIAR LA CARPETA node_modules/tinymce A LA MISMA ALTURA QUE LA DE api/ CON LOS ESTILOS CUSTOM EN /tinymce/skins/content/ Y EN /tinymce/skins/ui/ ***
        // base_url: `${process.env.REACT_APP_API_DOMAIN}/tinymce/`,
        // skin: "CUSTOM",
        // content_css: `${process.env.REACT_APP_API_DOMAIN}/tinymce/skins/content/CUSTOM/content.min.css`,

        // // *** FUNCIONA!!! ***
        // content_css: `${process.env.REACT_APP_API_DOMAIN}/tinymce/skins/content/CUSTOM/content.min.css`,
        // // skin_css: `${process.env.REACT_APP_API_DOMAIN}/tinymce/skins/ui/CUSTOM/skin.min.css`,
        // theme_css: `${process.env.REACT_APP_API_DOMAIN}/tinymce/skins/ui/CUSTOM/skin.min.css`,

        // // content_url: `${process.env.REACT_APP_API_DOMAIN}/tinymce/skins/content/CUSTOM`,
        // // theme_url: `${process.env.REACT_APP_API_DOMAIN}/tinymce/skins/content/CUSTOM`,
        // // theme_url: `${process.env.REACT_APP_API_DOMAIN}/tinymce/themes/silver`,
        // content_url: `${process.env.REACT_APP_API_DOMAIN}/tinymce/skins/content/CUSTOM`,
        // theme_url: `${process.env.REACT_APP_API_DOMAIN}/tinymce/skins/content/CUSTOM`,
        // theme_url: `${process.env.REACT_APP_API_DOMAIN}/tinymce/themes/silver`,

        content_style: 'body {font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif !important; font-size: .875rem}',

        height: 300,
        menubar: true,
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount'
        ],
        toolbar:
        'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help',
        powerpaste_allow_local_images: true,
        image_advtab: true,
      }}
      id={props.id}
      name={props.name}
      value={props.value}
      onEditorChange={props.onEditorChange}
    />
  );
}
