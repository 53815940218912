import React, { useState } from "react";
import { connect } from "react-redux";
import { toggleSidebar } from "../redux/actions/sidebarActions";

import { Link, NavLink, withRouter, Router, Redirect } from "react-router-dom";

import {
  Button,
  Row,
  Col,
  Collapse,
  Navbar,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  // ListGroup,
  // ListGroupItem,
  Form,
  Input
} from "reactstrap";

// import { toastr } from "react-redux-toastr";
import { toastr } from "react-redux-toastr";

import {
  AlertCircle,
  Bell,
  BellOff,
  Home,
  MessageCircle,
  PieChart,
  Settings,
  User,
  UserPlus,
  HelpCircle,
} from "react-feather";

import enFlag from "../assets/img/flags/gb.png";
import esFlag from "../assets/img/flags/es.png";
import deFlag from "../assets/img/flags/de.png";

import avatar1 from "../assets/img/avatars/avatar.jpg";
import avatar3 from "../assets/img/avatars/avatar-3.jpg";
import avatar4 from "../assets/img/avatars/avatar-4.jpg";
import avatar5 from "../assets/img/avatars/avatar-5.jpg";

import Route from '../routes/Routes'
import NavbarDropdown from "./NavbarDropdown";
import NavbarDropdownItem from "./NavbarDropdownItem";
import LogOutDropdownItem from "./LogOutDropdownItem";

// import { fetchApi } from "./Functions";
import {
  handleErrors,
  showToastr,
  validateEmail,
  validateFields,
  excludeDataFetch,
  fetchApi,
} from "./Functions";

class NavbarComponent extends React.Component {

  constructor(props) {

    super(props);

    this.state = {

      // CAMPO DEL TOKEN
      token: localStorage.getItem('token') || sessionStorage.getItem('token'),

      // nombre_usuario: "",

      // // CAMPOS LOADING
      isLoading: true,
      // isLoadingModal: true,

      // ARRAY CON LOS NOMBRES DE LAS VARIABLES A EXCLUIR DEL ENVIO A LA API
      dataExcludeComponent: [],
      dataExcludeParentComponent: [
        "token",
        // "modalType",
        // "modalTitle",
        // "modalText",
        // "modalIsOpen",
        "isLoading",
        // "isLoadingModal",
        // "updateLists",
        // "errorValidation",
        // "errorForm",
        // "errorGeneral",
        // "toastrIsOpen",
        "notifications",
        "messages",
        "dataExcludeComponent",
        "dataExcludeParentComponent",
        "dataIncludeParentComponent",
        "dataIncludeComponent"
      ],

      dataIncludeParentComponent: [],
      dataIncludeComponent: [],

      notifications: [
        {
          type: "important",
          title: "Update completed",
          description: "Restart server 12 to complete the update.",
          time: "2h ago"
        },
        {
          type: "default",
          title: "Lorem ipsum",
          description: "Aliquam ex eros, imperdiet vulputate hendrerit et.",
          time: "6h ago"
        },
        {
          type: "login",
          title: "Login from 192.186.1.1",
          description: "",
          time: "6h ago"
        },
        {
          type: "request",
          title: "New connection",
          description: "Anna accepted your request.",
          time: "12h ago"
        }
      ],
      messages: [
        {
          name: "Ashley Briggs",
          avatar: avatar5,
          description: "Nam pretium turpis et arcu. Duis arcu tortor.",
          time: "15m ago"
        },
        {
          name: "Carlos Pérez",
          avatar: avatar1,
          description: "Curabitur ligula sapien euismod vitae.",
          time: "2h ago"
        },
        {
          name: "Stacie Hall",
          avatar: avatar4,
          description: "Pellentesque auctor neque nec urna.",
          time: "4h ago"
        },
        {
          name: "Bertha Martin",
          avatar: avatar3,
          description: "Aenean tellus metus, bibendum sed, posuere ac, mattis non.",
          time: "5h ago"
        }
      ]
    }

    this.componentDidMount = this.componentDidMount.bind(this);
    
    this.handleErrors = handleErrors.bind(this);
    this.showToastr = showToastr.bind(this);
    this.validateEmail = validateEmail.bind(this);
    this.validateFields = validateFields.bind(this);
    this.excludeDataFetch = excludeDataFetch.bind(this);
    this.fetchApi = fetchApi.bind(this);

  }
  
  componentDidMount() {

    // if (localStorage.getItem("token")) {
      
    //   const token = localStorage.getItem("token");
    //   const encodedPayload = token.split('.')[1];
    //   const usuario = JSON.parse(atob(encodedPayload));
    //   // this.setState({ nombre_usuario: usuario["nombre"] });
    //   // alert("USUARIO EN LOCALSTORAGE: " + usuario["nombre"]);
    //   // this.hola();
    //   this.fetchApi("/api/_generic/get_username.php");
    // }

    // if (sessionStorage.getItem("token")) {

    //   const token = sessionStorage.getItem("token");
    //   const encodedPayload = token.split('.')[1];
    //   const usuario = JSON.parse(atob(encodedPayload));
    //   // this.setState({ nombre_usuario: usuario["nombre"] });
    //   // alert("USUARIO EN SESSION: " + usuario["nombre"]);
    // }
    if (this.state.token) {
      this.fetchApi("/api/_generic/get_username.php");
    }

    // this.setState({ isLoading: false });
  }

  render() {

    // const [nombre_usuario, setnombre_usuario] = useState(0);
    // alert(this.state.nombre_usuario);
    // let nombre_usuario = "";

    // if (localStorage.getItem("token")) {
    //   const token = localStorage.getItem("token");
    //   const encodedPayload = token.split('.')[1];
    //   const usuario = JSON.parse(atob(encodedPayload));
    //   nombre_usuario = usuario["nombre"];
    //   // alert("USUARIO EN LOCALSTORAGE: " + nombre_usuario);
    // }
    // if (sessionStorage.getItem("token")) {
    //   const token = sessionStorage.getItem("token");
    //   const encodedPayload = token.split('.')[1];
    //   const usuario = JSON.parse(atob(encodedPayload));
    //   nombre_usuario = usuario["nombre"];
    //   // alert("USUARIO EN SESSION: " + nombre_usuario);
    // }

    if (this.state.isLoading) { 
      return null;
    } else {

      return (

        <Navbar color="white" light expand className="sticky-top">
          <span
            className="sidebar-toggle d-flex mr-2"
            onClick={() => {
              this.props.dispatch(toggleSidebar());
            }}
          >
            <i className="hamburger align-self-center" />
          </span>

          {/* <Form inline>
            <Input
              type="text"
              placeholder="Buscar..."
              aria-label="Buscar"
              className="form-control-no-border mr-sm-2"
            />
          </Form> */}

          <Collapse navbar>

            <Nav className="ml-auto" navbar>

              {/* <NavbarDropdown
                header="New Messages"
                footer="Show all messages"
                icon={MessageCircle}
                count={this.state.messages.length}
                showBadge
              >
                {this.state.messages.map((item, key) => {
                  return (
                    <NavbarDropdownItem
                      key={key}
                      icon={
                        <img
                          className="avatar img-fluid rounded-circle"
                          src={item.avatar}
                          alt={item.name}
                        />
                      }
                      title={item.name}
                      description={item.description}
                      time={item.time}
                      spacing
                    />
                  );
                })}
              </NavbarDropdown> */}

              {/* <NavbarDropdown
                header="New Notifications"
                footer="Show all notifications"
                icon={BellOff}
                count={this.state.notifications.length}
              >
                {this.state.notifications.map((item, key) => {
                  let icon = <Bell size={18} className="text-warning" />;

                  if (item.type === "important") {
                    icon = <AlertCircle size={18} className="text-danger" />;
                  }
                  if (item.type === "login") {
                    icon = <Home size={18} className="text-primary" />;
                  }
                  if (item.type === "request") {
                    icon = <UserPlus size={18} className="text-success" />;
                  }

                  return (
                    <NavbarDropdownItem
                      key={key}
                      icon={icon}
                      title={item.title}
                      description={item.description}
                      time={item.time}
                    />
                  );
                })}
              </NavbarDropdown> */}

              {/* <UncontrolledDropdown nav inNavbar className="mr-2">
                <DropdownToggle nav caret className="nav-flag">
                  <img src={esFlag} alt="Español" />
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem>
                    <img
                      src={esFlag}
                      alt="Español"
                      width="20"
                      className="align-middle mr-1"
                    />
                    <span className="align-middle">Español</span>
                  </DropdownItem>
                  <DropdownItem>
                    <img
                      src={enFlag}
                      alt="English"
                      width="20"
                      className="align-middle mr-1"
                    />
                    <span className="align-middle">English</span>
                  </DropdownItem>
                  <DropdownItem>
                    <img
                      src={deFlag}
                      alt="Deutsche"
                      width="20"
                      className="align-middle mr-1"
                    />
                    <span className="align-middle">Deutsche</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown> */}

              <UncontrolledDropdown nav inNavbar>
                <span className="d-inline-block d-sm-none">
                  <DropdownToggle nav caret>
                    <Settings size={18} className="align-middle" />
                  </DropdownToggle>
                </span>
                <span className="d-none d-sm-inline-block">
                  <DropdownToggle nav caret>
                    {/* <img
                      src={avatar1}
                      className="avatar img-fluid rounded-circle mr-1"
                      alt={this.state.nombre_usuario}
                    /> */}
                    <span className="text-dark">{this.state.nombre_usuario}</span>
                  </DropdownToggle>
                </span>
                <DropdownMenu right>
                  <DropdownItem onClick={() => this.props.history.push(`/usuarios/`)}>
                    {/* <Link to={`/usuarios/`}> */}
                      <User size={18} className="align-middle mr-2" />
                      Mi cuenta
                    {/* </Link> */}
                  </DropdownItem>

                  {/*////////////////////CERRAR LA SESSION///////////////////////*/}
                  {/* <DrokpdownItem> */}
                  <LogOutDropdownItem />
                  {/* </DropdownItem> */}
                  {/*/////////////////////////////////////////////////////////////*/}

                  {/* <DropdownItem>
                    <PieChart size={18} className="align-middle mr-2" />
                    Analytics
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem>Settings & Privacy</DropdownItem> */}

                  <DropdownItem divider />

                  <DropdownItem onClick={(e) => { 
                      // window.location = "http://lan.fakolith.es/presupuestos_ayuda.php";
                      window.open("https://www.fakolith.es/presupuestos_ayuda.php", "_blank").focus();
                      e.preventDefault();
                      }}>
                      <HelpCircle size={18} className="align-middle mr-2" />
                      Ayuda
                  </DropdownItem>

                  {/* <DropdownItem>Sign out</DropdownItem> */}


                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Navbar>
      )
    }
  }
}

export default connect(store => ({
  app: store.app
}))(withRouter(NavbarComponent));


