import React from "react";
import { Link, withRouter } from 'react-router-dom';

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  // Col,
  Container,
  // Button,
  // Label,
  // FormGroup,
  // CustomInput,
  // Form,
  // Input,
  // Modal,
  // ModalBody,
  // ModalFooter,
  // ModalHeader
} from "reactstrap";

import GraphLines from "../components/GraphLines";
import FormEdit from "../components/FormEdit";

class EditPresupuestos extends React.Component {

  constructor(props) {

    super(props);

    this.state = {

      id_empresa: "",

      // CAMPOS LOADING
      isLoading: true,
      isLoadingModal: true,

      // escaladoHidden: false,
      
      fields: [
        {
          name: "id",
          label: "Id",
          type: "controlledInformative",
          hidden: false,
          readOnly: true,
          // PARA INICIALIZAR ID CUANDO SE HA ENTRADO POR EL MENU > NUEVO PRESUPUESTO
          defaultValue: this.props.match.params.id || "0",
        },
        {
          name: "numero",
          label: "Número",
          type: "controlledText",
          hidden: false,
          readOnly: true,
        },
        {
          name: "fecha_formateada",
          label: "Fecha",
          type: "controlledText",
          hidden: false,
          readOnly: true,
        },        
        {
          name: "fecha",
          label: "Fecha",
          type: "controlledInformative",
          hidden: false,
          readOnly: true,
        },        
        {
          name: "hora",
          label: "Hora",
          type: "controlledInformative",
          hidden: false,
          readOnly: true,
        },        
        {
          name: "id_estado_presupuesto",
          label: "Estado",
          type: "controlledRouteDynamicSelect",
          hidden: false,
          readOnly: false,
          //"table": "familias",
          //options: options_id_familia
          routeApi: "/api/_generic/select_estados.php"
        },
        {
          name: "referencia",
          label: "Referencia",
          type: "controlledText",
          hidden: false,
          readOnly: false,
        },        
        {
          name: "cliente",
          label: "Cliente",
          type: "controlledText",
          hidden: false,
          readOnly: false,
        },        
        {
          name: "poblacion",
          label: "Población",
          type: "controlledText",
          hidden: false,
          readOnly: false,
        },        
        {
          name: "id_tienda_presupuesto",
          label: "Delegación",
          type: "controlledDynamicSelect",
          hidden: false,
          readOnly: false,
          //"table": "familias",
          //options: options_id_familia
          // routeApi: "/api/_generic/desplegable_tiendas.php"
        },
        {
          name: "id_usuario_presupuesto",
          label: "Usuario",
          type: "controlledDynamicSelect",
          hidden: false,
          readOnly: false,
          //"table": "familias",
          //options: options_id_familia
          // routeApi: "/api/_generic/desplegable_usuarios.php"
        },
        {
          name: "telefono_comercial",
          label: "Teléfono usuario",
          type: "controlledText",
          hidden: false,
          readOnly: false,
        },
        {
          name: "email_comercial",
          label: "Email usuario",
          type: "controlledText",
          options: "",
          hidden: false,
          readOnly: false,
        },
        {
          name: "lineas_presupuestos",
          label: "Líneas del presupuesto",
          type: "controlledList",
          hidden: false,
          readOnly: false,
          component: "EditPresupuestos-LineasPresupuestos",
          routeList: "/lineas/",
          tablename: "lineas_presupuestos",
          idField: "id",
          deleteField: "int_status",
          routeApiList: "/api/presupuestos/list_presupuestos_lineas.php",
          routeApiActions: "/api/lineas_presupuestos/actions.php",
          numberFiles: 0,
          numberImages: 0,
          optionsIfField: "editable",
          pagination: false,
          rowOptions: "bt_edit_row bt_delete_row",
          columns: [
            {
              dataField: "descripcion",
              text: "Descripción",
              sort: true,
              headerStyle: { "width": "25%" }
            },
            {
              dataField: "cantidad",
              text: "Cantidad",
              sort: true,
              headerStyle: { "width": "10%" }
            },
            {
              dataField: "precio",
              text: "Precio",
              sort: true,
              headerStyle: { "width": "13%" }
            },
            {
              dataField: "descuento",
              text: "% dto.",
              sort: true,
              headerStyle: { "width": "9%" },
              // hidden: this.state.escaladoHidden
              // hidden: this.state["escaladoHidden"]
            },
            {
              dataField: "metros",
              text: "m2",
              sort: true,
              headerStyle: { "width": "7%" }
            },
            {
              dataField: "precio_m2",
              text: "Precio m2",
              sort: true,
              headerStyle: { "width": "12%" }
            },
            {
              dataField: "total",
              text: "Total",
              sort: true,
              headerStyle: { "width": "11%" }
            }
          ]          
        },
        // {
        //   name: "envios_presupuestos",
        //   label: "Envíos del presupuesto",
        //   type: "list",
        //   hidden: false,
        //   readOnly: true,
        //   routeList: "/presupuestos/usuarios/presupuestos/envios",
        //   tablename: "envios_presupuestos",
        //   idField: "id",
        //   deleteField: "int_status",
        //   routeApiList: "/api/presupuestos/listar_presupuestos_envios.php",
        //   routeApiActions: "/api/_generic/actions.php",
        //   numberFiles: 0,
        //   numberImages: 0,
        //   rowOptions: "bt_edit_row bt_delete_row",
        //   columns: [
        //     {
        //       dataField: "fecha",
        //       text: "Fecha",
        //       sort: true,
        //       headerStyle: { "width": "15%" }
        //     },
        //     {
        //       dataField: "hora",
        //       text: "Hora",
        //       sort: true,
        //       headerStyle: { "width": "15%" }
        //     },
        //     {
        //       dataField: "cliente",
        //       text: "Cliente",
        //       sort: true,
        //       headerStyle: { "width": "20%" }
        //     },
        //     {
        //       dataField: "poblacion",
        //       text: "Población",
        //       sort: true,
        //       headerStyle: { "width": "20%" }
        //     },
        //     {
        //       dataField: "email",
        //       text: "Email",
        //       sort: true,
        //       headerStyle: { "width": "30%" }
        //     }
        //   ]          
        // },
        {
          name: "escalado",
          label: "Dto. por cantidades",
          type: "controlledSwitch",
          hidden: false,
          readOnly: false,
        },        
        {
          name: "ed_total_fakolith",
          label: "Total productos",
          type: "controlledNumber",
          hidden: false,
          readOnly: true,
        },        
        {
          name: "portes",
          label: "Portes",
          type: "controlledNumber",
          hidden: false,
          readOnly: false,
        },        
        {
          name: "mano_de_obra",
          label: "Mano de obra",
          type: "controlledNumber",
          hidden: false,
          readOnly: false,
        },        
        {
          name: "otros_materiales",
          label: "Otros materiales",
          type: "controlledNumber",
          hidden: false,
          readOnly: false,
        },        
        {
          name: "otros_conceptos",
          label: "Otros conceptos",
          type: "controlledNumber",
          hidden: false,
          readOnly: false,
        },        
        {
          name: "total",
          label: "Total",
          type: "controlledNumber",
          hidden: false,
          readOnly: true,
        }, 
        // {
        //   name: "enlaces",
        //   label: "Enlaces a productos",
        //   type: "controlledSwitch",
        //   hidden: false,
        //   readOnly: false,
        // },        
        {
          name: "observaciones",
          label: "Observaciones",
          type: "editor",
          hidden: false,
          readOnly: false,
        },
        {
          name: "id_idioma",
          label: "Idioma del PDF",
          type: "controlledRouteDynamicSelect",
          hidden: false,
          readOnly: false,
          required: true,
          //"table": "familias",
          //options: options_id_familia
          routeApi: "/api/_generic/select_idiomas.php"
        },
        {
          name: "email",
          label: "Enviar PDF a",
          type: "controlledEmail",
          options: "bt_send",
          hidden: false,
          readOnly: false,
        },
        {
          name: "int_status",
          label: "Estado",
          type: "controlledInformative",
          hidden: true,
          readOnly: true,
        }
      ],
      /**********CAMPOS GENERALES  ************/
      component: "EditPresupuestos",
      routeList: "/",
      tablename: "presupuestos",
      idField: "id",
      deleteField: "int_status",
      routeApiControl: "/api/presupuestos/control.php",
      routeApiDeleteFile: "/api/_generic/delete_file.php",
      routeApiDeleteImage: "/api/_generic/delete_image.php",
      routeApiActions: "/api/presupuestos/actions.php",
      numberFiles: 0,
      numberImages: 0, 
      readOnly: false,
      options: "bt_save bt_duplicate bt_back bt_delete",
      informativeHidden: true,
      autoSave: true,
      updateListForm: true,
      initialFetch: true,

      // ARRAY CON LOS NOMBRES DE LAS VARIABLES A EXCLUIR DEL ENVIO A LA API
      dataExcludeParentComponent: [
        "options_id_usuario_presupuesto",
        "options_id_tienda_presupuesto"
      ],

      // ARRAY CON LOS NOMBRES DE LAS VARIABLES TIPO HIDDEN O READONLY DEL ESTADO A INCLUIR DEL ENVIO A LA API
      dataIncludeParentComponent: []

    }

    this.componentDidMount = this.componentDidMount.bind(this);

  }

  componentDidMount() {

    if (localStorage.getItem("token")) {
      
      const token = localStorage.getItem("token");
      const encodedPayload = token.split('.')[1];
      const usuario = JSON.parse(atob(encodedPayload));
      this.setState({ id_empresa: usuario["id_empresa"] });
      // alert("USUARIO EN LOCALSTORAGE: " + usuario["id_empresa"]);
    }

    if (sessionStorage.getItem("token")) {

      const token = sessionStorage.getItem("token");
      const encodedPayload = token.split('.')[1];
      const usuario = JSON.parse(atob(encodedPayload));
      this.setState({ id_empresa: usuario["id_empresa"] });
      // alert("USUARIO EN SESSION: " + usuario["id_empresa"]);
    }

    this.setState({ isLoading: false });
  }

  render () {

    if (this.state.isLoading) { 
      return null;
    } else {

      return (
        <Container fluid className="p-0">
          <h1 className="h3 mb-3">Presupuesto</h1>
          <Card>
            <CardHeader>
              <CardTitle tag="h5">Editar presupuesto</CardTitle>
              <h6 className="card-subtitle text-muted">
                Introduce la información referente a este presupuesto.
              </h6>
            </CardHeader>
            <CardBody>
              <FormEdit
                component={this.state.component}
                fields={this.state.fields} 
                routeList={this.state.routeList} 
                tablename={this.state.tablename} 
                idField={this.state.idField} 
                deleteField={this.state.deleteField}
                idValue={this.props.match.params.id}
                routeApiControl={this.state.routeApiControl} 
                routeApiDeleteFile={this.state.routeApiDeleteFile} 
                routeApiDeleteImage={this.state.routeApiDeleteImage} 
                routeApiActions={this.state.routeApiActions} 
                numberFiles={this.state.numberFiles} 
                numberImages={this.state.numberImages}
                readOnly={this.state.readOnly} 
                options={this.state.options}
                informativeHidden={this.state.informativeHidden}
                autoSave={this.state.autoSave}
                dataExcludeParentComponent={this.state.dataExcludeParentComponent}
                dataIncludeParentComponent={this.state.dataIncludeParentComponent}
                updateListForm={this.state.updateListForm}
                initialFetch={this.state.initialFetch}
              /> 
            </CardBody>
          </Card>           
        </Container>
      )
    }
  }
}

//export default EditPresupuestos;
export default withRouter(EditPresupuestos);
