import React from "react";
import { Link, withRouter } from 'react-router-dom';
import { Card, CardBody, CardHeader, CardTitle, Container, Row, Col, Button} from "reactstrap";


import FormList from "../components/FormList";

//import { tableData, tableColumns } from "./data3.js";
//import { tableColumns } from "./data4_columns.js";

class ListPresupuestos extends React.Component {

  constructor(props) {

    super(props);

    this.state = {

      id_empresa: "",

      // CAMPOS LOADING
      isLoading: true,
      isLoadingModal: true,
      
      columns: [
        {
          dataField: "numero",
          text: "Número",
          sort: true,
          headerStyle: { "width": "5%" }
        },
        {
          dataField: "fecha_formateada",
          text: "Fecha",
          sort: true,
          headerStyle: { "width": "10%" }
        },
        // {
        //   dataField: "fecha",
        //   text: "Fecha",
        //   sort: true,
        //   headerStyle: { "width": "15%" }
        // },
        // {
        //   dataField: "nombre_empresa",
        //   text: "Empresa",
        //   sort: true,
        //   headerStyle: { "width": "20%" }
        // },
        // {
        //   dataField: "nombre_tienda",
        //   text: "Tienda",
        //   sort: true,
        //   headerStyle: { "width": "10%" }
        // },
        {
          dataField: "nombre_estado",
          text: "Estado",
          sort: true,
          headerStyle: { "width": "10%" }
        },
        {
          dataField: "nombre",
          text: "Usuario",
          sort: true,
          headerStyle: { "width": "15%" }
        },
        {
          dataField: "referencia",
          text: "Referencia",
          sort: true,
          headerStyle: { "width": "20%" }
        },
        {
          dataField: "cliente",
          text: "Cliente",
          sort: true,
          headerStyle: { "width": "15%" }
        },
        {
          dataField: "total",
          text: "Total",
          sort: true,
          headerStyle: { "width": "10%" }
        }
        //RESERVAMOS EL 14% DEL ANCHO PARA LA COLUMNA DE ACCIONES
      ],

      /**********CAMPOS GENERALES  ************/
      component: "ListPresupuestos",
      routeList: "/",
      tablename: "presupuestos",
      idField: "id",
      deleteField: "int_status",
      routeApiList: "/api/presupuestos/list.php",
      // routeApiDeleteFile: "/api/_generic/delete_file.php",
      // routeApiDeleteImage: "/api/_generic/delete_image.php",
      // routeApiActions: "/api/_generic/actions.php",
      routeApiActions: "/api/presupuestos/actions.php",
      numberFiles: 0,
      numberImages: 0, 
      readOnly: false,
      rowOptions: "bt_edit_row bt_duplicate_row bt_delete_row",

      // ARRAY CON LOS NOMBRES DE LAS VARIABLES A EXCLUIR DEL ENVIO A LA API
      dataExcludeParentComponent: [
        "options_id_usuario_presupuesto",
        "options_id_tienda_presupuesto"
      ],

      // ARRAY CON LOS NOMBRES DE LAS VARIABLES TIPO HIDDEN O READONLY DEL ESTADO A INCLUIR DEL ENVIO A LA API
      dataIncludeParentComponent: []
    };

    this.componentDidMount = this.componentDidMount.bind(this);
  }

  componentDidMount() {

    if (localStorage.getItem("token")) {
      
      const token = localStorage.getItem("token");
      const encodedPayload = token.split('.')[1];
      const usuario = JSON.parse(atob(encodedPayload));
      this.setState({ id_empresa: usuario["id_empresa"] });
      // alert("USUARIO EN LOCALSTORAGE: " + usuario["id_empresa"]);
    }

    if (sessionStorage.getItem("token")) {

      const token = sessionStorage.getItem("token");
      const encodedPayload = token.split('.')[1];
      const usuario = JSON.parse(atob(encodedPayload));
      this.setState({ id_empresa: usuario["id_empresa"] });
      // alert("USUARIO EN SESSION: " + usuario["id_empresa"]);
    }

    this.setState({ isLoading: false });
  }

  render () {

    // // alert(localStorage.getItem("id_empresa"));
    // // alert(sessionStorage.getItem("id_empresa"));
    // let id_empresa = "";

    // if (localStorage.getItem("token")) {
    //   const token = localStorage.getItem("token");
    //   const encodedPayload = token.split('.')[1];
    //   const usuario = JSON.parse(atob(encodedPayload));
    //   id_empresa = usuario["id_empresa"];
    //   // alert("EMPRESA EN LOCALSTORAGE: " + id_empresa);
    // }
    // if (sessionStorage.getItem("token")) {
    //   const token = sessionStorage.getItem("token");
    //   const encodedPayload = token.split('.')[1];
    //   const usuario = JSON.parse(atob(encodedPayload));
    //   id_empresa = usuario["id_empresa"];
    //   // alert("EMPRESA EN SESSION: " + id_empresa);
    // }

    if (this.state.isLoading) { 
      return null;
    } else {

      return (
        <Container fluid className="p-0">
          <h1 className="h3 mb-3">Presupuestos</h1>
          <Card>
            <CardHeader>
              <CardTitle tag="h5">Listado de presupuestos</CardTitle>
              <h6 className="card-subtitle text-muted">
                Crea, edita o elimina los presupuestos que desees.
              </h6>
            </CardHeader>
            <CardBody>
              <FormList 
                component={this.state.component}
                tablename={this.state.tablename}
                columns={this.state.columns}
                routeList={this.state.routeList}
                routeApiList={this.state.routeApiList}
                routeApiActions={this.state.routeApiActions}
                rowOptions={this.state.rowOptions}
                idField={this.state.idField}
                deleteField={this.state.deleteField}
                numberFiles={this.state.numberFiles}
                numberImages={this.state.numberImages}
                readOnly={this.state.readOnly}
                dataExcludeParentComponent={this.state.dataExcludeParentComponent}
                dataIncludeParentComponent={this.state.dataIncludeParentComponent}
                idParent={this.state.id_empresa}
              />
            </CardBody>
          </Card>
        </Container>
      );
    }  
  };
}

// export default ListPresupuestos;
export default withRouter(ListPresupuestos);
