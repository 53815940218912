import React from "react";
import { Link, withRouter } from 'react-router-dom';

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  // Col,
  Container,
  // Button,
  // Label,
  // Form,
  // FormGroup,
  // Input,
  // CustomInput,
  // Modal,
  // ModalBody,
  // ModalFooter,
  // ModalHeader,
  // Spinner,
} from "reactstrap";

// import CKEditor from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import { Editor } from '@tinymce/tinymce-react';
// import Select from "react-select";
// import FormList from "../components/FormList";
// import InputFile from "../components/InputFile";
// import InputImage from "../components/InputImage";
// import ModalWindow from "../components/ModalWindow";

import GraphLines from "../components/GraphLines";
// import Formulario from "../components/Formulario";
import FormEdit from "../components/FormEdit";


class EditPresupuestosLineas extends React.Component {

  constructor(props) {

    super(props);

    this.state = {

      // CAMPOS LOADING
      isLoading: true,
      isLoadingModal: true,

      fields: [
        {
          name: "id",
          label: "Id",
          type: "controlledInformative",
          hidden: true,
          readOnly: true,
        },
        {
          name: "id_presupuesto",
          label: "Id presupuesto",
          type: "controlledInformative",
          hidden: true,
          readOnly: true,
        },
        {
          name: "id_tarifa",
          label: "Id tarifa",
          type: "controlledInformative",
          hidden: true,
          readOnly: true,
        },
        {
          name: "ed_tarifa",
          label: "Tarifa",
          type: "controlledDynamicSelect",
          hidden: true,
          readOnly: false,
          required: true,
          // routeApi: "/api/productos/obtener_productos.php",
          fetchApi: true,
        },
        {
          name: "id_producto",
          label: "Producto",
          type: "controlledInformative",
          hidden: false,
          readOnly: true,
        },
        {
          name: "id_sistema_presupuesto",
          label: "Sistema",
          type: "controlledInformative",
          hidden: false,
          readOnly: true,
        },
        {
          name: "descripcion",
          label: "Descripción",
          type: "controlledInformative",
          hidden: true,
          readOnly: true,
        },
        {
          name: "id_familia",
          label: "Familia",
          type: "controlledDynamicSelect",
          hidden: false,
          readOnly: false,
          // routeApi: "/api/_generic/desplegable_familias.php",
          // functionOnChange: "handleChangeFamilia"
          fetchApi: true,
        },
        {
          name: "ed_producto_o_sistema",
          label: "Producto o sistema",
          type: "controlledDynamicSelect",
          hidden: false,
          readOnly: false,
          required: true,
          // routeApi: "/api/productos/obtener_productos.php",
          fetchApi: true,
        },
        {
          name: "ed_nombre_producto",
          label: "Producto del sistema",
          type: "controlledText",
          hidden: true,
          readOnly: true,
          required: false,
          // routeApi: "/api/productos/obtener_productos.php",
          fetchApi: false,
        },
        {
          name: "metros",
          label: "Metros cuadrados",
          type: "controlledNumber",
          hidden: true,
          readOnly: false,
          required: true,
          fetchApi: true,
        },
        {
          name: "id_rendimiento",
          label: "Rendimiento habitual",
          type: "controlledDynamicSelect",
          hidden: true,
          readOnly: false,
          required: false,
          fetchApi: true,
        },
        {
          name: "rendimiento",
          label: "Rendimiento personalizado",
          type: "controlledDynamicSelect",
          hidden: true,
          readOnly: false,
          required: true,
          fetchApi: true,
        },
        {
          name: "espesor",
          label: "Rendimiento personalizado",
          type: "controlledDynamicSelect",
          hidden: true,
          readOnly: false,
          required: true,
          fetchApi: true,
        },
        {
          name: "id_producto_envase",
          label: "Envase",
          type: "controlledDynamicSelect",
          hidden: false,
          readOnly: false,
          required: true,
          fetchApi: true,
        },
        {
          name: "cantidad",
          label: "Cantidad",
          type: "controlledNumber",
          hidden: false,
          readOnly: false,
          required: true,
          fetchApi: true,
        },
        {
          name: "ed_unidades_necesarias",
          label: "Unidades necesarias",
          type: "controlledText",
          hidden: true,
          readOnly: true,
        },                               
        {
          name: "envases",
          label: "Envases",
          type: "controlledDynamicSelect",
          hidden: true,
          readOnly: false,
          required: true,
          fetchApi: true,
        },            
        {
          name: "ed_unidades_necesarias_redondeadas",
          label: "Unidades totales",
          type: "controlledText",
          hidden: false,
          readOnly: true,
        },                                                  
        {
          name: "id_incremento",
          label: "Incremento",
          type: "controlledDynamicSelect",
          hidden: false,
          readOnly: false,
          required: true,
          fetchApi: true,
        },                            
        {
          name: "ed_precio",
          label: "Precio",
          type: "controlledText",
          hidden: false,
          readOnly: true,
        }, 
        {
          name: "ed_precio_total_sin_descuento",
          label: "Total sin dto.",
          type: "controlledInformative",
          hidden: false,
          readOnly: true,
        },      
        {
          name: "escalado",
          label: "Escalado",
          type: "controlledSwitch",
          hidden: true,
          readOnly: true,
        },        
        {
          name: "descuento",
          label: "Descuento (%)",
          type: "controlledNumber",
          hidden: false,
          readOnly: false,
          fetchApi: true,
        },        
        {
          name: "ed_precio_total",
          label: "Total",
          type: "controlledText",
          hidden: false,
          readOnly: true,
        },      
        {
          name: "ed_precio_m2",
          label: "Precio m2",
          type: "controlledText",
          hidden: false,
          readOnly: true,
        },        
                       
                         
        /********** CAMPOS INFORMATIVOS ************/
        {
          name: "bloque",
          label: "Bloque",
          type: "controlledInformative",
          hidden: true,
          readOnly: true,
        },
        {
          name: "ed_tipo_bloque",
          label: "Tipo de bloque",
          type: "controlledInformative",
          hidden: true,
          readOnly: true,
        },
        {
          name: "ed_tipo_producto",
          label: "Tipo de producto",
          type: "controlledInformative",
          hidden: true,
          readOnly: true,
        },
        {
          name: "unidades",
          label: "Unidades",
          type: "controlledInformative",
          hidden: false,
          readOnly: true,
        },
        {
          name: "ed_espesor_min",
          label: "Espesor mínimo",
          type: "controlledInformative",
          hidden: false,
          readOnly: true,
        },                               
        {
          name: "ed_cantidad_m2",
          label: "Cantidad por m2 de espesor mínimo",
          type: "controlledInformative",
          hidden: false,
          readOnly: true,
        },                               
        {
          name: "ed_cantidad_envase_min",
          label: "Cantidad envase mín.",
          type: "controlledInformative",
          hidden: false,
          readOnly: true,
        },                               
        {
          name: "ed_ids_envases",
          label: "Ids de envases",
          type: "controlledInformative",
          hidden: false,
          readOnly: true,
        },     
        {
          name: "ed_envases_seleccionados",
          label: "Envases seleccionados",
          type: "controlledInformative",
          hidden: false,
          readOnly: true,
        },                               
        {
          name: "ed_precios_por_envase",
          label: "Precio unidad por envase",
          type: "controlledInformative",
          hidden: false,
          readOnly: true,
        },                               
        {
          name: "ed_unidades_precios_por_envase",
          label: "Unidad precio por envase",
          type: "controlledInformative",
          hidden: false,
          readOnly: true,
        },                               
        {
          name: "ed_precios_totales_sin_descuento",
          label: "Precios totales sin dto.",
          type: "controlledInformative",
          hidden: false,
          readOnly: true,
        },                               
        {
          name: "ed_precios_totales",
          label: "Precios totales",
          type: "controlledInformative",
          hidden: false,
          readOnly: true,
        },     
        {
          name: "ed_orden_sistema_presupuesto",
          label: "Orden en sistema",
          type: "controlledInformative",
          hidden: true,
          readOnly: true,
        },                               
        {
          name: "ed_num_productos_sistema_presupuesto",
          label: "Número de productos",
          type: "controlledInformative",
          hidden: true,
          readOnly: true,
        },                                                         
        {
          name: "ed_producto_principal",
          label: "Producto principal",
          type: "controlledInformative",
          hidden: false,
          readOnly: true,
        },                               
        {
          name: "ed_cantidad_producto_principal",
          label: "Cantidad producto principal",
          type: "controlledInformative",
          hidden: false,
          readOnly: true,
        },                               
        {
          name: "ed_proporcion",
          label: "Proporcion",
          type: "controlledInformative",
          hidden: true,
          readOnly: true,
        },
        {
          name: "int_status",
          label: "Estado",
          type: "controlledInformative",
          hidden: true,
          readOnly: true,
        },
        // {
        //   name: "wizard",
        //   label: "Wizard",
        //   type: "wizard",
        //   hidden: false,
        //   readOnly: true,
        // },

      ],
      /********** CAMPOS GENERALES ************/
      component: "EditPresupuestosLineas",
      routeList: "/",
      tablename: "lineas_presupuestos",
      idField: "id",
      deleteField: "int_status",
      routeApiControl: "/api/lineas_presupuestos/control.php",
      routeApiDeleteFile: "/api/_generic/delete_file.php",
      routeApiDeleteImage: "/api/_generic/delete_image.php",
      routeApiActions: "/api/lineas_presupuestos/actions.php",
      numberFiles: 0,
      numberImages: 0, 
      readOnly: false,
      options: "bt_save bt_back bt_delete",
      informativeHidden: true,
      autoSave: false,
      updateListForm: true,
      initialFetch: true,
      skipRedirectSubmit: false,
      submitButtonText: "Guardar",

      // ARRAY CON LOS NOMBRES DE LAS VARIABLES A EXCLUIR DEL ENVIO A LA API
      dataExcludeParentComponent: [
        "options_id_familia",
        "options_ed_producto_o_sistema",
        "options_rendimiento",
        "options_espesor",
        "options_id_incremento",
        // "envases_bloque",
      ],

      // ARRAY CON LOS NOMBRES DE LAS VARIABLES TIPO HIDDEN O READONLY DEL ESTADO A INCLUIR DEL ENVIO A LA API
      dataIncludeParentComponent: [
        "id_producto_envaseHidden",
        "rendimientoHidden",
        "espesorHidden",
        "id_incrementoHidden",
      ]

    };

  }  

  render () {

      return (
        <Container fluid className="p-0">
        <h1 className="h3 mb-3">Presupuesto</h1>
        <Card>
          <CardHeader>
            <CardTitle tag="h5">Editar línea de presupuesto</CardTitle>
            <h6 className="card-subtitle text-muted">
              Introduce la información referente a esta línea del presupuesto.
            </h6>
          </CardHeader>
        <CardBody>
            <FormEdit
              component={this.state.component}
              fields={this.state.fields} 
              routeList={`${this.state.routeList}${this.props.match.params.id_presupuesto}`} 
              tablename={this.state.tablename} 
              idField={this.state.idField} 
              deleteField={this.state.deleteField}
              idValue={this.props.match.params.id}
              idParent={this.props.match.params.id_presupuesto}
              routeApiControl={this.state.routeApiControl} 
              routeApiDeleteFile={this.state.routeApiDeleteFile} 
              routeApiDeleteImage={this.state.routeApiDeleteImage} 
              routeApiActions={this.state.routeApiActions} 
              numberFiles={this.state.numberFiles} 
              numberImages={this.state.numberImages}
              readOnly={this.state.readOnly} 
              options={this.state.options}
              informativeHidden={this.state.informativeHidden}
              dataExcludeParentComponent={this.state.dataExcludeParentComponent}
              dataIncludeParentComponent={this.state.dataIncludeParentComponent}
              initialFetch={this.state.initialFetch}
              skipRedirectSubmit={this.state.skipRedirectSubmit}
              submitButtonText={this.state.submitButtonText}
            /> 
          </CardBody>
        </Card>           
      </Container>
    ) 
  }
}

//export default EditPresupuestosLineas;
export default withRouter(EditPresupuestosLineas);
