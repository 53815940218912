import React from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";

// import { PrivateRoute } from '../components/PrivateRoute';

import {
  landing as landingRoutes,
  dashboard as dashboardRoutes,
  page as pageRoutes
} from "./index";

import DashboardLayout from "../layouts/Dashboard";
import LandingLayout from "../layouts/Landing";
import AuthLayout from "../layouts/Auth";
import Page404 from "../pages/auth/Page404";

import ScrollToTop from "../components/ScrollToTop";

// import LoginSignIn from "../login/LoginSignIn";
// import LoginForgot from "../login/LoginForgot";
// import LoginPassword from "../login/LoginPassword";

import EditEmpresas from "../empresas/EditEmpresas";
import EditEmpresasTiendas from "../empresas/EditEmpresasTiendas";
// import EditEmpresasUsuarios from "../empresas/EditEmpresasUsuarios_OBSOLETO";
// import EditarEmpresasPresupuestos from "../empresas/EditarEmpresasPresupuestos";
import EditUsuarios from "../usuarios/EditUsuarios";
// import EditarUsuariosPresupuestos from "../usuarios/EditarUsuariosPresupuestos";
import EditPresupuestos from "../presupuestos/EditPresupuestos";
import EditPresupuestosLineas from "../presupuestos/EditPresupuestosLineas";

// import EditarDuplicarTablas from "../configuracion/duplicar/EditarDuplicarTablas";
// import EditarDuplicarTablasRelaciones from "../configuracion/duplicar/EditarDuplicarTablasRelaciones";

import LoginPassword from "../login/LoginPassword";
import EditRegister from "../registro/EditRegister";
import ConfirmRegister from "../registro/ConfirmRegister";

const childRoutes = (Layout, routes) =>
  routes.map(({ children, path, component: Component }, index) =>
    children ? (
      // Route item with children
      children.map(({ path, component: Component }, index) => (
        <Route
          key={index}
          path={path}
          exact
          render={props => (
            <Layout>
              <Component {...props} />
            </Layout>
          )}
        />
      ))
    ) : (
      // Route item without children
      <Route
        key={index}
        path={path}
        exact
        render={props => (
          <Layout>
            <Component {...props} />
          </Layout>
        )}
      />
    )
  );  

const Routes = (props) => (
  <Router>
    <ScrollToTop>
      <Switch>
        {childRoutes(LandingLayout, landingRoutes)}
        {childRoutes(DashboardLayout, dashboardRoutes)}
        {childRoutes(AuthLayout, pageRoutes)}

        {/******** RUTAS DE EDITAR NO VISIBLES DESDE EL MENÚ PRINCIPAL ******/}

        {/******** RUTAS DE LOGIN *****
        <Route
              key={101}
              path="/login"
              exact
              render={(props) => (
                <AuthLayout>
                  <LoginSignIn  {...props}/> 
                </AuthLayout>
              )}
            />
            <Route
              key={102}
              path="/olvide/"
              exact
              render={(props) => (
                <AuthLayout>
                  <LoginForgot  {...props}/> 
                </AuthLayout>
              )}
            />
            <Route
              key={103}
              path="/contrasenya/:keystring"
              exact
              render={(props) => (
                <AuthLayout>
                  <LoginPassword  {...props}/> 
                </AuthLayout>
              )}
            />*/}

        {/******** RUTAS DE EDICION O CON PARAMETROS ******/}

        <Route
          key={140}
          path="/:id"
          exact
          render={props => (
            <DashboardLayout>
              <EditPresupuestos {...props} />
            </DashboardLayout>
          )}
        />
        <Route
          key={141}
          // path="/presupuestos/lineas/:id_presupuesto/:id/:escalado"
          path="/lineas/:id_presupuesto/:id"
          exact
          render={props => (
            <DashboardLayout>
              <EditPresupuestosLineas {...props} />
            </DashboardLayout>
          )}
        />

        {/* <Route
          key={134}
          path="/empresas/:id"
          exact
          render={props => (
            <DashboardLayout>
              <EditEmpresas {...props} />
            </DashboardLayout>
          )}
        /> */}
        <Route
          key={135}
          path="/empresas/tiendas/:id_empresa/:id"
          exact
          render={props => (
            <DashboardLayout>
              <EditEmpresasTiendas {...props} />
            </DashboardLayout>
          )}
        />
        {/* <Route
          key={136}
          path="/empresas/usuarios/:id_empresa/:id"
          exact
          render={props => (
            <DashboardLayout>
              <EditEmpresasUsuarios {...props} />
            </DashboardLayout>
          )} */}
        />
        {/* <Route
          key={137}
          path="/empresas/presupuestos/:id_empresa/:id"
          exact
          render={props => (
            <DashboardLayout>
              <EditarEmpresasPresupuestos {...props} />
            </DashboardLayout>
          )}
        /> */}
        {/* <Route
          key={138}
          path="/usuarios/:id"
          exact
          render={props => (
            <DashboardLayout>
              <EditUsuarios {...props} />
            </DashboardLayout>
          )}
        /> */}
        <Route
          key={139}
          path="/usuarios/presupuestos/:id_usuario/:id"
          exact
          render={props => (
            <DashboardLayout>
              {/* <EditarUsuariosPresupuestos {...props} /> */}
              <EditPresupuestos {...props} />
            </DashboardLayout>
          )}
        />

        {/* METEMOS AQUI TAMBIEN LAS RUTAS AL RESETEO DE CONTRASEÑA Y AL REGISTRO DEL SISTEMA POR SI TIENE TOKEN VALIDO PERO CLICA EN EL LINK DEL EMAIL PARA RESETEARLA */}
        <Route
          key={301}
          path="/contrasenya/:keystring"
          exact
          render={(props) => (
            <AuthLayout>
              <LoginPassword  {...props}/> 
            </AuthLayout>
          )}
        />
        <Route
          key={302}
          path="/registro/:keystring"
          exact
          render={(props) => (
            <AuthLayout>
              <EditRegister  {...props}/> 
            </AuthLayout>
          )}
        />
        <Route
          key={303}
          path="/registro/confirmar/:keystring"
          exact
          render={(props) => (
            <AuthLayout>
              <ConfirmRegister  {...props}/> 
            </AuthLayout>
          )}
        />

        <Route
          render={() => (
            <AuthLayout>
              <Page404 />
            </AuthLayout>
          )}
        />

      </Switch>
    </ScrollToTop>

    {/* COMPROBAMOS SI HAY REDIRECCION POR RUTA INICIAL AL ABRIR LA APP */}
    {/* { (props["redirect"]) ? (
      <Redirect to={props["redirect"]}></Redirect>
    ) : null } */}

  </Router>
);

export default Routes;
