import React from "react";
import { Link, withRouter } from 'react-router-dom';

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  // Col,
  Container,
  // Button,
  // Label,
  // FormGroup,
  // CustomInput,
  // Form,
  // Input,
  // Modal,
  // ModalBody,
  // ModalFooter,
  // ModalHeader
} from "reactstrap";

import GraphLines from "../components/GraphLines";
import FormEdit from "../components/FormEdit";

class EditEmpresas extends React.Component {

  constructor(props) {

    super(props);

    this.state = {

      id_empresa: "",

      // CAMPOS LOADING
      isLoading: true,
      isLoadingModal: true,
      
      fields: [
        {
          name: "id",
          label: "Id",
          type: "controlledInformative",
          hidden: true,
          readOnly: true,
          required: true,
        },
        {
          name: "codigo",
          label: "Código",
          type: "controlledInformative",
          hidden: true,
          readOnly: true,
          required: false,
        },
        {
          name: "nombre",
          label: "Nombre",
          type: "controlledText",
          hidden: false,
          readOnly: false,
          required: true,
        },
        {
          name: "direccion",
          label: "Dirección",
          type: "controlledText",
          hidden: false,
          readOnly: false,
          required: false,
        },
        {
          name: "poblacion",
          label: "Población",
          type: "controlledText",
          hidden: false,
          readOnly: false,
          required: false,
        },
        {
          name: "cod_postal",
          label: "Cód. postal",
          type: "controlledText",
          hidden: false,
          readOnly: false,
          required: false,
        },
        {
          name: "provincia",
          label: "Provincia",
          type: "controlledRouteDynamicSelect",
          hidden: false,
          readOnly: false,
          required: false,
          routeApi: "/api/_generic/select_provincias.php"
        },
        {
          name: "id_pais",
          label: "País",
          type: "controlledRouteDynamicSelect",
          hidden: false,
          readOnly: false,
          required: true,
          routeApi: "/api/_generic/select_paises.php"
        },
        {
          name: "email",
          label: "Email",
          type: "controlledText",
          hidden: true,
          readOnly: false,
          required: false,
        },
        {
          name: "cif",
          label: "CIF",
          type: "controlledText",
          hidden: true,
          readOnly: false,
          required: false,
        },
        // {
        //   name: "imagen1",
        //   label: "Imagen cas.",
        //   type: "image",
        //   imageNumber: "1",
        //   hidden: false,
        //   readOnly: false,
        // },
        {
          name: "imagen1",
          label: "Logotipo",
          type: "imageFile",
          imageNumber: "1",
          hidden: false,
          readOnly: true,
          routeHidden: true,
        },
        {
          name: "keystring",
          label: "Keystring",
          type: "controlledInformative",
          hidden: false,
          readOnly: true,
          required: true,
        },
        {
          name: "id_version",
          label: "Versión software",
          type: "controlledRouteDynamicSelect",
          hidden: false,
          readOnly: true,
          routeApi: "/api/_generic/select_versiones.php"
        },
        {
          name: "email_invitacion",
          label: "Invitar a usuarios de la empresa",
          type: "controlledEmail",
          options: "bt_send",
          hidden: false,
          readOnly: false,
          fetchApi: false,
        },
        {
          name: "usuarios_presupuestos",
          label: "Usuarios",
          type: "controlledList",
          hidden: false,
          readOnly: true,
          routeList: "/empresas/usuarios/",
          tablename: "_calculith_usuarios",
          idField: "id",
          deleteField: "int_status",
          routeApiList: "/api/empresas/list_empresas_usuarios.php",
          routeApiActions: "/api/_generic/actions.php",
          numberFiles: 0,
          numberImages: 0,
          rowOptions: "bt_edit_row bt_delete_row",
          columns: [
            {
              dataField: "nombre",
              text: "Nombre",
              sort: true,
              headerStyle: { "width": "36%" }
            },
            {
              dataField: "telefono",
              text: "Teléfono",
              sort: true,
              headerStyle: { "width": "20%" }
            },
            {
              dataField: "email",
              text: "Email",
              sort: true,
              headerStyle: { "width": "30%" }
            },
            // {
            //   dataField: "fecha",
            //   text: "Fecha",
            //   sort: true,
            //   headerStyle: { "width": "16%" }
            // }            
          ]          
        },
        {
          name: "tiendas_presupuestos",
          label: "Delegaciones",
          type: "controlledList",
          hidden: false,
          readOnly: false,
          routeList: "/empresas/tiendas/",
          tablename: "tiendas_presupuestos",
          idField: "id",
          deleteField: "int_status",
          routeApiList: "/api/empresas/list_empresas_tiendas.php",
          routeApiActions: "/api/_generic/actions.php",
          numberFiles: 0,
          numberImages: 0,
          rowOptions: "bt_edit_row bt_delete_row",
          columns: [
            {
              dataField: "nombre",
              text: "Nombre",
              sort: true,
              headerStyle: { "width": "32%" }
            },
            {
              dataField: "poblacion",
              text: "Población",
              sort: true,
              headerStyle: { "width": "30%" }
            },
            {
              dataField: "nombre_provincia",
              text: "Provincia",
              sort: true,
              headerStyle: { "width": "22%" }
            }            
          ]          
        },

        // {
        //   name: "presupuestos",
        //   label: "Presupuestos",
        //   type: "list",
        //   hidden: false,
        //   readOnly: true,
        //   routeList: "/empresas/presupuestos/",
        //   tablename: "presupuestos",
        //   idField: "id",
        //   deleteField: "int_status",
        //   routeApiList: "/api/empresas/listar_empresas_presupuestos.php",
        //   routeApiActions: "/api/_generic/actions.php",
        //   numberFiles: 0,
        //   numberImages: 0,
        //   rowOptions: "bt_edit_row bt_delete_row",
        //   columns: [
        //     {
        //       dataField: "numero",
        //       text: "Número",
        //       sort: true,
        //       headerStyle: { "width": "10%" }
        //     },
        //     {
        //       dataField: "referencia",
        //       text: "Referencia",
        //       sort: true,
        //       headerStyle: { "width": "20%" }
        //     },
        //     // {
        //     //   dataField: "nombre_tienda",
        //     //   text: "Tienda",
        //     //   sort: true,
        //     //   headerStyle: { "width": "10%" }
        //     // },
        //     {
        //       dataField: "nombre_usuario",
        //       text: "Usuario",
        //       sort: true,
        //       headerStyle: { "width": "20%" }
        //     },
        //     {
        //       dataField: "cliente",
        //       text: "Cliente",
        //       sort: true,
        //       headerStyle: { "width": "20%" }
        //     },
        //     {
        //       dataField: "total",
        //       text: "Total",
        //       sort: true,
        //       headerStyle: { "width": "10%" }
        //     }

        //   ]          
        // },
        {
          name: "int_status",
          label: "Estado",
          type: "controlledInformative",
          hidden: true,
          readOnly: false,
          required: true,
        },
      ],
      /**********CAMPOS GENERALES  ************/
      component: "EditEmpresas",
      routeList: "/empresas/",
      tablename: "_calculith_empresas",
      idField: "id",
      deleteField: "int_status",
      routeApiControl: "/api/_generic/control.php",
      routeApiDeleteFile: "/api/_generic/delete_file.php",
      routeApiDeleteImage: "/api/_generic/delete_image.php",
      routeApiDeleteImageFile: "/api/_generic/delete_image_file.php",
      routeApiActions: "/api/empresas/actions.php",
      numberFiles: 0,
      numberImages: 0, 
      numberImageFiles: 1,
      readOnly: false,
      options: "bt_save",
      informativeHidden: true,
      autoSave: true,
      initialFetch: true,

      // ARRAY CON LOS NOMBRES DE LAS VARIABLES A EXCLUIR DEL ENVIO A LA API
      dataExcludeParentComponent: [
        // "options_provincia",
        // "routeThumbnail1",
        // "routeImage1",
      ],

      // ARRAY CON LOS NOMBRES DE LAS VARIABLES TIPO HIDDEN O READONLY DEL ESTADO A INCLUIR DEL ENVIO A LA API
      dataIncludeParentComponent: []
    }

    this.componentDidMount = this.componentDidMount.bind(this);

  }

  componentDidMount() {

    if (localStorage.getItem("token")) {
      
      const token = localStorage.getItem("token");
      const encodedPayload = token.split('.')[1];
      const usuario = JSON.parse(atob(encodedPayload));
      this.setState({ id_empresa: usuario["id_empresa"] });
      // alert("USUARIO EN LOCALSTORAGE: " + usuario["id_empresa"]);
    }

    if (sessionStorage.getItem("token")) {

      const token = sessionStorage.getItem("token");
      const encodedPayload = token.split('.')[1];
      const usuario = JSON.parse(atob(encodedPayload));
      this.setState({ id_empresa: usuario["id_empresa"] });
      // alert("USUARIO EN SESSION: " + usuario["id_empresa"]);
    }

    this.setState({ isLoading: false });
  }

  render () {

    if (this.state.isLoading) { 
      return null;
    } else {

      return (
        <Container fluid className="p-0">
          <h1 className="h3 mb-3">Empresa</h1>
          <Card>
            <CardHeader>
              <CardTitle tag="h5">Editar empresa</CardTitle>
              <h6 className="card-subtitle text-muted">
                Introduce la información referente a la empresa.
              </h6>
            </CardHeader>
            <CardBody>
              <FormEdit 
                component={this.state.component}
                fields={this.state.fields} 
                routeList={this.state.routeList} 
                tablename={this.state.tablename} 
                idField={this.state.idField} 
                deleteField={this.state.deleteField}
                // idValue={this.props.match.params.id}
                // idValue={process.env.REACT_APP_ID_EMPRESA}
                idValue={this.state.id_empresa}
                // idParent={this.state.id_empresa}
                routeApiControl={this.state.routeApiControl} 
                routeApiDeleteFile={this.state.routeApiDeleteFile} 
                routeApiDeleteImage={this.state.routeApiDeleteImage} 
                routeApiDeleteImageFile={this.state.routeApiDeleteImageFile} 
                routeApiActions={this.state.routeApiActions} 
                numberFiles={this.state.numberFiles} 
                numberImages={this.state.numberImages} 
                numberImageFiles={this.state.numberImageFiles} 
                readOnly={this.state.readOnly} 
                options={this.state.options}
                informativeHidden={this.state.informativeHidden}
                autoSave={this.state.autoSave}
                dataExcludeParentComponent={this.state.dataExcludeParentComponent}
                dataIncludeParentComponent={this.state.dataIncludeParentComponent}
                initialFetch={this.state.initialFetch}
              /> 
            </CardBody>
          </Card>           
        </Container>
      )
    }
  }
}

//export default EditEmpresas;
export default withRouter(EditEmpresas);
