import React from "react";
import { Link, withRouter } from 'react-router-dom';

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  // Col,
  Container,
  // Button,
  // Label,
  // FormGroup,
  // CustomInput,
  // Form,
  // Input,
  // Modal,
  // ModalBody,
  // ModalFooter,
  // ModalHeader
} from "reactstrap";

import FormEdit from "../components/FormEdit";

class EditEmpresasTiendas extends React.Component {

  constructor(props) {

    super(props);

    this.state = {

      id_empresa: props.idParent,

      // CAMPOS LOADING
      isLoading: true,
      isLoadingModal: true,

      fields: [
        {
          name: "id",
          label: "Id",
          type: "controlledInformative",
          hidden: false,
          readOnly: true,
        },
        {
          name: "id_empresa",
          label: "Id empresa",
          type: "controlledParent",
          hidden: true,
          readOnly: true,
          // defaultValue: this.state.id_empresa,
        },
        {
          name: "nombre",
          label: "Nombre",
          type: "controlledText",
          hidden: false,
          readOnly: false,
          required: true,
        },
        {
          name: "direccion",
          label: "Dirección",
          type: "controlledText",
          hidden: false,
          readOnly: false,
        },
        {
          name: "poblacion",
          label: "Población",
          type: "controlledText",
          hidden: false,
          readOnly: false,
        },
        {
          name: "cod_postal",
          label: "Cód. postal",
          type: "controlledText",
          hidden: false,
          readOnly: false,
        },
        {
          name: "provincia",
          label: "Provincia",
          type: "controlledRouteDynamicSelect",
          hidden: false,
          readOnly: false,
          //"table": "familias",
          //options: options_id_familia
          routeApi: "/api/_generic/select_provincias.php"
        },
        {
          name: "id_pais",
          label: "País",
          type: "controlledRouteDynamicSelect",
          hidden: false,
          readOnly: false,
          required: true,
          routeApi: "/api/_generic/select_paises.php"
        },
        {
          name: "telefono",
          label: "Teléfono",
          type: "controlledText",
          hidden: false,
          readOnly: false,
        },
        {
          name: "email",
          label: "Email",
          type: "controlledText",
          hidden: false,
          readOnly: false,
        },        
        {
          name: "int_status",
          label: "Estado",
          type: "controlledInformative",
          hidden: true,
          readOnly: true,
          defaultValue: 3,
        }
      ],
      /**********CAMPOS GENERALES  ************/
      component: "EditEmpresasTiendas",
      routeList: "/empresas/",
      tablename: "tiendas_presupuestos",
      idField: "id",
      deleteField: "int_status",
      routeApiControl: "/api/_generic/control.php",
      routeApiDeleteFile: "/api/_generic/delete_file.php",
      routeApiDeleteImage: "/api/_generic/delete_image.php",
      routeApiDeleteImageFile: "/api/_generic/delete_image_file.php",
      routeApiActions: "/api/_generic/actions.php",
      numberFiles: 0,
      numberImages: 0, 
      readOnly: false,
      options: "bt_save bt_back bt_delete",
      informativeHidden: true,
      autoSave: false,
      initialFetch: true,

      // ARRAY CON LOS NOMBRES DE LAS VARIABLES A EXCLUIR DEL ENVIO A LA API
      dataExcludeParentComponent: [],

      // ARRAY CON LOS NOMBRES DE LAS VARIABLES TIPO HIDDEN O READONLY DEL ESTADO A INCLUIR DEL ENVIO A LA API
      dataIncludeParentComponent: []
    }
  }

  componentDidMount() {

    if (localStorage.getItem("token")) {
      
      const token = localStorage.getItem("token");
      const encodedPayload = token.split('.')[1];
      const usuario = JSON.parse(atob(encodedPayload));
      this.setState({ id_empresa: usuario["id_empresa"] });
      // alert("USUARIO EN LOCALSTORAGE: " + usuario["id_empresa"]);
    }

    if (sessionStorage.getItem("token")) {

      const token = sessionStorage.getItem("token");
      const encodedPayload = token.split('.')[1];
      const usuario = JSON.parse(atob(encodedPayload));
      this.setState({ id_empresa: usuario["id_empresa"] });
      // alert("USUARIO EN SESSION: " + usuario["id_empresa"]);
    }

    this.setState({ isLoading: false });
  }

  render () {

    if (this.state.isLoading) { 
      return null;
    } else {

      return (
        <Container fluid className="p-0">
          <h1 className="h3 mb-3">Empresas</h1>
          <Card>
            <CardHeader>
              <CardTitle tag="h5">Editar delegación</CardTitle>
              <h6 className="card-subtitle text-muted">
                Introduce la información referente a la delegación de la empresa.
              </h6>
            </CardHeader>
            <CardBody>
              <FormEdit 
                component={this.state.component}
                fields={this.state.fields} 
                // routeList={`${this.state.routeList}${this.props.match.params.id_empresa}`}
                routeList={this.state.routeList}
                tablename={this.state.tablename} 
                idField={this.state.idField} 
                deleteField={this.state.deleteField}
                idValue={this.props.match.params.id}
                idParent={this.props.match.params.id_empresa}
                routeApiControl={this.state.routeApiControl} 
                routeApiDeleteFile={this.state.routeApiDeleteFile} 
                routeApiDeleteImage={this.state.routeApiDeleteImage} 
                routeApiActions={this.state.routeApiActions} 
                numberFiles={this.state.numberFiles} 
                numberImages={this.state.numberImages} 
                readOnly={this.state.readOnly} 
                options={this.state.options}
                informativeHidden={this.state.informativeHidden}
                autoSave={this.state.autoSave}
                dataExcludeParentComponent={this.state.dataExcludeParentComponent}
                dataIncludeParentComponent={this.state.dataIncludeParentComponent}                
                initialFetch={this.state.initialFetch}
              />            
            </CardBody>
          </Card>
        </Container>
      )
    }
  }
}

//export default EditEmpresasTiendas;
export default withRouter(EditEmpresasTiendas);
