import React, { useState } from "react";
import { Link, withRouter } from 'react-router-dom';

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  // Col,
  Container,
  // Button,
  // Label,
  // FormGroup,
  // CustomInput,
  // Form,
  // Input,
  // Modal,
  // ModalBody,
  // ModalFooter,
  // ModalHeader
} from "reactstrap";

import GraphLines from "../components/GraphLines";
import FormEdit from "../components/FormEdit";

class EditUsuarios extends React.Component {

  constructor(props) {

    super(props);

    this.state = {

      // ESTO FUNCIONA MAS RAPIDO, PERO SE TIENE MAS CONTROL SI SE CARGA EN EL componentDidMount CON isLoading
      // id_usuario: JSON.parse(atob(localStorage.getItem("token").split('.')[1]))["id"] || JSON.parse(atob(sessionStorage.getItem("token").split('.')[1]))["id"],
      
      id_usuario: "",

      // CAMPOS LOADING
      isLoading: true,
      isLoadingModal: true,
      
      fields: [
        {
          name: "id",
          label: "Id",
          type: "controlledInformative",
          hidden: true,
          readOnly: true,
        },
        {
          name: "nombre",
          label: "Nombre y apellidos",
          type: "controlledText",
          hidden: false,
          readOnly: false,
          required: true,
        },
        // {
        //   name: "id_empresa",
        //   label: "Empresa",
        //   type: "dynamicSelect",
        //   hidden: true,
        //   readOnly: true,
        //   //"table": "familias",
        //   //options: options_id_familia
        //   routeApi: "/api/_generic/desplegable_empresas.php"
        // },
        {
          name: "id_empresa",
          label: "Id empresa",
          type: "controlledInformative",
          hidden: false,
          readOnly: true,
        },
        {
          name: "telefono",
          label: "Teléfono",
          type: "controlledText",
          hidden: false,
          readOnly: false,
        },
        {
          name: "email",
          label: "Email",
          type: "controlledText",
          hidden: false,
          readOnly: false,
          required: true,
        },
        {
          name: "contrasenya",
          label: "Contraseña",
          type: "controlledInformative",
          hidden: false,
          readOnly: true,
        },
        {
          name: "fecha",
          label: "Fecha",
          type: "controlledInformative",
          hidden: true,
          readOnly: true,
        },
        {
          name: "hora",
          label: "Hora",
          type: "controlledInformative",
          hidden: true,
          readOnly: true,
        },
        {
          name: "keystring",
          label: "Keystring",
          type: "controlledInformative",
          hidden: true,
          readOnly: true,
        },
        {
          name: "presupuestos",
          label: "Presupuestos",
          type: "controlledList",
          hidden: false,
          readOnly: false,
          routeList: "/usuarios/presupuestos/",
          tablename: "presupuestos",
          idField: "id",
          deleteField: "int_status",
          routeApiList: "/api/usuarios/list_usuarios_presupuestos.php",
          routeApiActions: "/api/presupuestos/actions.php",
          numberFiles: 0,
          numberImages: 0,
          rowOptions: "bt_edit_row bt_duplicate_row bt_delete_row",
          columns: [
            {
              dataField: "numero",
              text: "Número",
              sort: true,
              headerStyle: { "width": "10%" }
            },
            {
              dataField: "fecha_formateada",
              text: "Fecha",
              sort: true,
              headerStyle: { "width": "10%" }
            },
            {
              dataField: "nombre_estado",
              text: "Estado",
              sort: true,
              headerStyle: { "width": "10%" }
            },
            {
              dataField: "referencia",
              text: "Referencia",
              sort: true,
              headerStyle: { "width": "25%" }
            },
            // {
            //   dataField: "nombre_tienda",
            //   text: "Tienda",
            //   sort: true,
            //   headerStyle: { "width": "10%" }
            // },
            // {
            //   dataField: "nombre",
            //   text: "Usuario",
            //   sort: true,
            //   headerStyle: { "width": "20%" }
            // },
            {
              dataField: "cliente",
              text: "Cliente",
              sort: true,
              headerStyle: { "width": "20%" }
            },
            {
              dataField: "total",
              text: "Total",
              sort: true,
              headerStyle: { "width": "10%" }
            }
          ]          
        },
        {
          name: "int_status",
          label: "Estado",
          type: "controlledInformative",
          hidden: false,
          readOnly: true,
        },
      ],

      /**********CAMPOS GENERALES  ************/
      component: "EditUsuarios",
      routeList: "/usuarios/",
      tablename: "_calculith_usuarios",
      idField: "id",
      deleteField: "int_status",
      routeApiControl: "/api/_generic/control.php",
      routeApiDeleteFile: "/api/_generic/delete_file.php",
      routeApiDeleteImage: "/api/_generic/delete_image.php",
      routeApiActions: "/api/_generic/actions.php",
      numberFiles: 0,
      numberImages: 0, 
      readOnly: false,
      options: "bt_save",
      informativeHidden: true,
      autoSave: true,
      initialFetch: true,

      // ARRAY CON LOS NOMBRES DE LAS VARIABLES A EXCLUIR DEL ENVIO A LA API
      dataExcludeParentComponent: [],

      // ARRAY CON LOS NOMBRES DE LAS VARIABLES TIPO HIDDEN O READONLY DEL ESTADO A INCLUIR DEL ENVIO A LA API
      dataIncludeParentComponent: []

    }

    this.componentDidMount = this.componentDidMount.bind(this);

  }

  componentDidMount() {

    if (localStorage.getItem("token")) {
      
      const token = localStorage.getItem("token");
      const encodedPayload = token.split('.')[1];
      const usuario = JSON.parse(atob(encodedPayload));
      this.setState({ id_usuario: usuario["id"] });
      // alert("USUARIO EN LOCALSTORAGE: " + usuario["id"]);
    }

    if (sessionStorage.getItem("token")) {

      const token = sessionStorage.getItem("token");
      const encodedPayload = token.split('.')[1];
      const usuario = JSON.parse(atob(encodedPayload));
      this.setState({ id_usuario: usuario["id"] });
      // alert("USUARIO EN SESSION: " + usuario["id"]);
    }

    this.setState({ isLoading: false });
  }

  render () {

    // alert(this.state.id_usuario);

    if (this.state.isLoading) { 
      return null;
    } else {

      return (
        <Container fluid className="p-0">
          <h1 className="h3 mb-3">Usuarios</h1>
          <Card>
            <CardHeader>
              <CardTitle tag="h5">Editar usuario</CardTitle>
              <h6 className="card-subtitle text-muted">
                Introduce la información referente a este usuario.
              </h6>
            </CardHeader>
            <CardBody>
              <FormEdit 
                component={this.state.component}
                fields={this.state.fields} 
                routeList={this.state.routeList} 
                tablename={this.state.tablename} 
                idField={this.state.idField} 
                deleteField={this.state.deleteField}
                // idValue={this.props.match.params.id}
                // idValue={process.env.REACT_APP_ID_USUARIO}
                idValue={this.state.id_usuario}
                routeApiControl={this.state.routeApiControl} 
                routeApiDeleteFile={this.state.routeApiDeleteFile} 
                routeApiDeleteImage={this.state.routeApiDeleteImage} 
                routeApiActions={this.state.routeApiActions} 
                numberFiles={this.state.numberFiles} 
                numberImages={this.state.numberImages} 
                readOnly={this.state.readOnly} 
                options={this.state.options}
                informativeHidden={this.state.informativeHidden}
                autoSave={this.state.autoSave}
                dataExcludeParentComponent={this.state.dataExcludeParentComponent}
                dataIncludeParentComponent={this.state.dataIncludeParentComponent}
                initialFetch={this.state.initialFetch}
              /> 
            </CardBody>
          </Card>           
        </Container>
      )
    }
  }
}

//export default EditUsuarios;
export default withRouter(EditUsuarios);
